import {
    Drawer,
    Theme,
    CSSObject,
    List,
    ListItem,
    ListItemIcon,
    Box,
    styled
} from '@mui/material';

const NavBarLight = {
    background: 'linear-gradient(to bottom, #268CF2, #123C5E)'
}
const NavBarDark = {
    background: 'linear-gradient(to bottom, #123C5E, #282833)'
}

export const MobileNavigationDrawer = styled( Drawer )(({ theme }) => ({
    anchor: 'bottom',
    variant: 'persistent'
}))