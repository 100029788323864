import { PaletteMode } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppState, IResults, IAlert } from '../../constants/interfaces';
import { AppStatusCodes } from '../../constants/enums';
import { updatePreferences } from '../AccessThunks';

const initialState: IAppState = {
    alerts: [],
    error: false,
    errorCode: 0,
    errorMessage: '',
    processing: false,
    loading: true,
    version: process.env.APP_VERSION ? process.env.APP_VERSION : '',
    appName: process.env.APP_NAME ? process.env.APP_NAME : '',
    appTheme: 'light',
    navMenu: true
}

const persistAppSettings = ( state: IAppState ) => {
    localStorage.setItem(
        'appSettings',
        JSON.stringify({
            appTheme: state.appTheme,
            navMenu: state.navMenu
        })
    )
}

export const AppState = createSlice({
    name: 'AppState',
    initialState,
    reducers: {
        toggleNavMenu: ( state, action: PayloadAction< boolean > ) => {
            state.navMenu = action.payload;
            persistAppSettings( state );
        },
        toggleLoading: ( state, action: PayloadAction< boolean > ) => {
            state.loading = action.payload;
        },
        toggleError: ( state, action: PayloadAction<{ error: boolean, errorMessage: string, errorCode?: number }> ) => {
            state.error = action.payload.error;
            state.errorCode = action.payload.errorCode ? action.payload.errorCode : 0;
            state.errorMessage = action.payload.errorMessage;
        },
        updateTheme: ( state, action: PayloadAction< PaletteMode > ) => {
            state.appTheme = action.payload;
            persistAppSettings( state )
        },
        restoreAppSettings: ( state, action: PayloadAction< IAppState > ) => ({
            ...state,
            ...action.payload
        }),
        addAlert: ( state, action: PayloadAction< IAlert > ) => {
            state.alerts = [ ...state.alerts, action.payload ];
        },
        removeAlert: ( state, action: PayloadAction< number > ) => {
            const alert = state.alerts?.filter( alert => alert.id !== action.payload );
            if ( alert ) {
                state.alerts = alert
            }
        }
    },
    extraReducers: ( builder ) => {
        builder.addCase( updatePreferences.fulfilled, ( state, action: PayloadAction< IResults > ) => {
            if ( !action.payload.success ) return;
            if ( state.appTheme !== action.payload.payload.ThemeMode ) {
                state.appTheme = action.payload.payload.ThemeMode;
                persistAppSettings( state );
            }
        });
    }
})

export const {
    toggleLoading,
    toggleNavMenu,
    toggleError,
    updateTheme,
    restoreAppSettings,
    addAlert,
    removeAlert
} = AppState.actions;

export default AppState.reducer;

