import { DateTime } from 'luxon';

export const browserTimezone = () => {
    let TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return TZ;
};

export const formatDateTimeUTC_TZ = ( dateTime: string, dateOnly?: boolean, isLT?: boolean ) => {
	const endFormat = "dd-MM-yyyy hh:mm a"
    const dateOnlyFormat = "dd-MM-yyyy";
	const dateTimeStr = dateTime;

	const dt = DateTime.fromISO( dateTimeStr, { zone: isLT ? 'Australia/Melbourne' : 'utc' } );
	if ( dt.isValid && !isLT ) {
		return dt.setZone( 'Australia/Melbourne' ).toFormat( dateOnly ? dateOnlyFormat : endFormat );
	}
    if ( dt.isValid && isLT ) {
        return dt.toFormat( dateOnly ? dateOnlyFormat : endFormat );
    }
    return dateTime
}

export function daysBetweenDates( date1: string, date2: string ) {
    const dt1 = DateTime.fromISO( date1 );
    const dt2 = DateTime.fromISO( date2 );
    
    let sum = dt2.diff( dt1, 'days' ).toObject().days;
    const diff = sum ? sum : 0;
    return Math.abs(diff);
}

export function currentDate() {
    let nowISO = DateTime.now();
    return nowISO;
}

export function currentDayStart() {
    let nowISO = DateTime.local().toISODate();
    return nowISO;
}

export function validateDate( date: string, isFuture?: boolean ) {
    const dt = DateTime.fromISO( date );
    if ( isFuture === null || isFuture === undefined ) {
        // If isFuture is null or undefined, only validate if the date is today or in the past
        return dt.isValid && dt <= DateTime.now();
    } else if ( isFuture ) {
        // If isFuture is true, validate the date for past or future
        return dt.isValid;
    } else {
        // If isFuture is false, validate the date only for past
        return dt.isValid && dt <= DateTime.now();
    }
}

export function validateExpiry( expiry: Date ): boolean {
    const dt = DateTime.fromJSDate( expiry );
    return dt.isValid && dt > DateTime.now();
}