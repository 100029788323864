import React, { createContext, useState, useContext, useMemo } from 'react';
import { useAppSelector } from '../redux/Store';
import { INavMenuItem } from '../constants/interfaces';
import { NavMenuItems } from '../constants';

type DrawerContextType = {
    isOpen: boolean;
    menu: Array< INavMenuItem >;
}

type DrawerContextProviderProps = {
    children: React.ReactNode
}

const DrawerContext = createContext< DrawerContextType | undefined >( undefined )

export const DrawerContextProvider = ({ children }: DrawerContextProviderProps ) => {
    const { navMenu } = useAppSelector(( state ) => state.AppState )

    const value = useMemo(() => ({ isOpen: navMenu, menu: NavMenuItems }), [ navMenu ])

    return (
        <DrawerContext.Provider value={ value }>
            { children }
        </DrawerContext.Provider>
    )
}

export const useDrawerContext = () => {
    const context = useContext( DrawerContext )
    if ( context === undefined ) {
        throw new Error( 'useDrawerContext must be used within a DrawerContextProvider' )
    }
    return context
}