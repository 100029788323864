import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { AnimatedCheckCircle, AnimatedCancelCircle, AnimatedLoadingCheckCircle } from "./Icons";
import { LoginCompleteContainer } from "../styled";
import { AppStatusCodes } from "../../constants/enums";

interface MFALoaderProps {
    minMilliSeconds: number;
    mfaResponse: number;
    currentView: 'credentials' | 'mfa' | 'complete';
}

export const MFALoader = ({ minMilliSeconds, mfaResponse, currentView }: MFALoaderProps ) => {
    const [ success, setSuccess ] = useState< 'authorized' | 'loading' | 'error' >( 'loading' )
    const [ minRuntimeComplete, setMinRuntimeComplete ] = useState< boolean >( false )

    useEffect(() => {
        let timer: NodeJS.Timeout
        if ( currentView === 'complete' && !minRuntimeComplete ) {
            timer = setTimeout( () => setMinRuntimeComplete( true ), minMilliSeconds )
            timer
        }

        return () => clearTimeout( timer )
    }, [ currentView ])

    useEffect(() => {
        if ( mfaResponse === AppStatusCodes.AUTHENTICATED ) {
            setSuccess( 'authorized' )
        }
        if ( mfaResponse !== AppStatusCodes.AUTHENTICATED && mfaResponse !== 0 ) {
            setSuccess( 'error' )
        }
        if ( mfaResponse === 0 ) {
            setSuccess( 'loading' )
            setMinRuntimeComplete( false )
        }
    }, [ currentView, mfaResponse ])

    return (
        <LoginCompleteContainer view={ currentView }>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    paddingTop: '3rem',
                }}
            >
                <AnimatedLoadingCheckCircle sx={{ fontSize: '5rem', position: 'absolute', zIndex: 0 }} />
                { success === 'authorized' && minRuntimeComplete && <AnimatedCheckCircle sx={{ fontSize: '5rem', position: 'absolute', zIndex: 1 }} /> }
                { success === 'error' && minRuntimeComplete && <AnimatedCancelCircle sx={{ fontSize: '5rem', position: 'absolute', zIndex: 1 }} /> }
            </Box>
        </LoginCompleteContainer>
    )
}

