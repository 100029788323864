import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect, useCallback } from "react";
import { Box, Typography, FormHelperText, FormControl } from "@mui/material";
import { DigitInput } from "../../styled";
import { StepUpInputContainer } from "../../styled";
import { MFAMethod } from "../../../constants/enums";
import { useMediaWidth } from "../../../hooks";


interface SICInputProps {
    length: number;
    error: boolean;
    view: 'code' | 'loading';
    customMessage?: string;
    helpText?: string;
    onCompletion: ( v: string ) => void;
    onCancel: () => void;
}

export const StepUpInput = ({ length, error, view, customMessage, helpText, onCompletion, onCancel }: SICInputProps ) => {
    const [ values, setValues ] = useState< Array< string >>( new Array( length ).fill( '' ) );
    const [ activeIndex, setActiveIndex ] = useState< number >( 0 );
    const inputs = useRef<( HTMLInputElement | null )[]>( [] );
    const isDesktop = useMediaWidth({ direction: 'up', breakPoint: 'tablet' });


    useEffect(() => {
        if ( view === 'code' ) {
            // Timeout used to allow the view to finish transitioning before focusing on the first input
            setTimeout( () => inputs.current[ 0 ]?.focus(), 500 );
        }
    }, [ view ]);

    useEffect(() => {
        const value = values.join( '' );
        if ( value.length === length ) {
            setTimeout(() => {
                onCompletion( value );
                resetValues();
            }, 500 );
        }
    }, [ values ]);

    useEffect(() => {
        if ( error ) {
            resetValues();
        }
    }, [ error ]);

    const inputRef = useCallback(( el: HTMLInputElement | null, index: number ) => {
        inputs.current[ index ] = el;
    }, [] );
    
    const handleChange = ( e: ChangeEvent< HTMLInputElement | HTMLTextAreaElement >, index: number ) => {
        const { value } = e.target;
        if ( /^[0-9]$/.test( value ) || value === '' ) {
            const newvalues = [ ...values ];
            newvalues[ index ] = value;
            setValues( newvalues );

            if ( value !== '' && index < length - 1 ) {
                setActiveIndex( index + 1 );
                setTimeout(() => {
                    inputs.current[ index + 1 ]?.focus()
                }, 0 ); // Timeout used to defer to next event loop so all DOM updates complete
            }
        }
    }

    const handleKeyDown = ( e: KeyboardEvent< HTMLDivElement | HTMLTextAreaElement >, index: number ) => {
        if ( e.key === 'Backspace' && values[ index ] === '' && index > 0 ) {
            e.preventDefault();
            const newvalues = [ ...values ];
            newvalues[ index - 1 ] = '';
            setValues( newvalues );
            setActiveIndex( index - 1 );
            setTimeout(() => {
                inputs.current[ index - 1 ]?.focus();
            }, 0 );
        }
    }

    const handleBlur = ( index: number ) => {
        if ( index === activeIndex ) {
            // Re-focus if user tries to leave the active input
            setTimeout(() => inputs.current[ activeIndex ]?.focus(), 0 );
        }
    };

    const handlePaste = ( e: React.ClipboardEvent< HTMLInputElement > ) => {
        e.preventDefault();
        const paste = e.clipboardData.getData( 'text' ).slice( 0, length );
        const newValues = [ ...values ];
        for ( let i = 0; i < paste.length; i++ ) {
            newValues[ i ] = paste[ i ];
        }

        setValues( newValues );
        const nextIndex = paste.length < length ? paste.length : length - 1;
        setActiveIndex( nextIndex );
        setTimeout(() => inputs.current[ nextIndex ]?.focus(), 0 );
    }

    const onInput = ( e: React.FormEvent< HTMLInputElement > ) => {
        e.preventDefault();
        const otp = e.currentTarget.value.slice( 0, length );
        const newValues = [ ...values ];
        for ( let i = 0; i < otp.length; i++ ) {
            newValues[ i ] = otp[ i ];
        }
        setValues( newValues );
        const nextIndex = otp.length < length ? otp.length : length - 1;
        setActiveIndex( nextIndex );
        setTimeout(() => inputs.current[ nextIndex ]?.focus(), 0 );
    }

    const resetValues = () => setValues( new Array( length ).fill( '' ) );

    return (
        <StepUpInputContainer
            view={ view }
        >
            <FormControl 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'center',
                    marginBottom: '-1rem',
                    marginLeft: '0.4rem'
                }}
            >
                {
                    values.map(( value, index ) => (
                        <DigitInput
                            key={ index }
                            value={ value }
                            onChange={ ( e ) => handleChange( e, index ) }
                            onKeyDown={ ( e ) => handleKeyDown( e, index ) }
                            onBlur={ () => handleBlur( index ) }
                            onPaste={ index === 0 ? handlePaste : undefined }
                            autoComplete='off'
                            onInput={ index === 0 ?  onInput : undefined }
                            inputProps={{ 
                                maxLength: 1,
                                inputMode: 'numeric',
                                tabIndex: index === activeIndex ? 0 : -1,
                                readOnly: index !== activeIndex
                            }}
                            inputRef={ ( el ) => inputRef( el, index ) }
                            error={ error }
                            view={ view }
                            animationdelay={ '0.5' }
                            disabled={ index > activeIndex}
                        />
                    ))
                }
            </FormControl>
            { error && helpText && (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <FormHelperText>{ helpText }</FormHelperText>
                </div>
            )}
        </StepUpInputContainer>
    )
}