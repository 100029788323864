import { INavMenuItem } from './interfaces';
import { RoutePaths, ReimbOperCostType, ContactUsSubject, AppEndpoints, Action } from './enums';
import { UUID } from '../types';
import { DateTime } from 'luxon';
import {
    HouseIcon,
    ReimbursementsIcon,
    DocumentsIcon,
    FAQsIcon,
    ContactUsIcon
} from '../components/UI/Icons';

// Sets the breakpoints for the base grid in the Layout components, these are passed to the child components
export const columnBase = {
    xsmobile: 4,
    mobile: 5,
    tablet: 8,
    laptop: 10,
    desktop: 12
};
export const global_BAD_DATE: string = DateTime.fromJSDate( new Date(-8640000000000000) ).toISO() || '';
export const global_EMPTY_UUID: UUID = "00000000-0000-0000-0000-000000000000";


export const NavMenuItems: Array< INavMenuItem > = [
    {
        // Home screen List of packages
        icon: HouseIcon({ outlined: true, size: 'medium', color: 'inherit', style: { color: 'white' } }),
        label: 'Home',
        path: RoutePaths.AUTHENTICATED_ROOT
    },
    {
        icon: ReimbursementsIcon({ outlined: true, size: 'medium', color: 'inherit', style: { color: 'white' } }),
        label: 'Reimbursements',
        path: RoutePaths.REIMBURSEMENTS
    },
    {
        icon: DocumentsIcon({ outlined: true, size: 'medium', color: 'inherit', style: { color: 'white' } }),
        label: 'Lease Documents',
        path: RoutePaths.DOCUMENTS
    },
    {
        icon: FAQsIcon({ outlined: true, size: 'medium', color: 'inherit', style: { color: 'white' } }),
        label: 'FAQs',
        path: RoutePaths.FAQS
    },
    {
        icon: ContactUsIcon({ outlined: true, size: 'medium', color: 'inherit', style: { color: 'white' } }),
        label: 'Contact Us',
        path: RoutePaths.CONTACT_US
    }
]

export const MobileNavMenuItems: Array< INavMenuItem > = [
    {
        // Home screen List of packages
        icon: HouseIcon({ outlined: true, size: 'medium', color: 'inherit', style: { color: 'white' } }),
        label: 'Home',
        path: RoutePaths.AUTHENTICATED_ROOT
    },
    {
        icon: ReimbursementsIcon({ outlined: true, size: 'medium', color: 'inherit', style: { color: 'white' } }),
        label: 'Reimbursements',
        path: RoutePaths.REIMBURSEMENTS
    },
    {
        icon: DocumentsIcon({ outlined: true, size: 'medium', color: 'inherit', style: { color: 'white' } }),
        label: 'Lease Documents',
        path: RoutePaths.DOCUMENTS
    },
    {
        icon: FAQsIcon({ outlined: true, size: 'medium', color: 'inherit', style: { color: 'white' } }),
        label: 'FAQs',
        path: RoutePaths.FAQS
    },
    {
        icon: ContactUsIcon({ outlined: true, size: 'medium', color: 'inherit', style: { color: 'white' } }),
        label: 'Contact Us',
        path: RoutePaths.CONTACT_US
    }
]

export const OperatingCostSelectOptions: Array< { key: number, label: string, value: ReimbOperCostType } > = [
    {
        key: ReimbOperCostType.FUEL,
        label: 'Fuel',
        value: ReimbOperCostType.FUEL
    },
    {
        key: ReimbOperCostType.MAINTENANCE,
        label: 'Maintenance',
        value: ReimbOperCostType.MAINTENANCE
    },
    {
        key: ReimbOperCostType.INSURANCE,
        label: 'Insurance',
        value: ReimbOperCostType.INSURANCE
    },
    {
        key: ReimbOperCostType.TYRES,
        label: 'Tyres',
        value: ReimbOperCostType.TYRES
    },
    {
        key: ReimbOperCostType.REGISTRATION,
        label: 'Registration',
        value: ReimbOperCostType.REGISTRATION
    },
    {
        key: ReimbOperCostType.CTP,
        label: 'Compulsory Third Party',
        value: ReimbOperCostType.CTP
    },
    {
        key: ReimbOperCostType.CAR_WASH,
        label: 'Car Wash',
        value: ReimbOperCostType.CAR_WASH
    },
    {
        key: ReimbOperCostType.CARBON_OFFSET,
        label: 'Carbon Offset',
        value: ReimbOperCostType.CARBON_OFFSET
    },
    {
        key: ReimbOperCostType.TOLLS,
        label: 'Toll Costs',
        value: ReimbOperCostType.TOLLS
    },
    {
        key: ReimbOperCostType.LEASE_PROTECTION_INSURANCE,
        label: 'Lease Protection Insurance',
        value: ReimbOperCostType.LEASE_PROTECTION_INSURANCE
    },
    {
        key: ReimbOperCostType.HOME_CHARGING_REIMBURSEMENT,
        label: 'Home Charging Reimbursement',
        value: ReimbOperCostType.HOME_CHARGING_REIMBURSEMENT
    },
    {
        key: ReimbOperCostType.OTHER,
        label: 'Other Expense Type',
        value: ReimbOperCostType.OTHER
    }
]

export const ContactUsSubjectOptions: Array<{ key: number, label: string, value: ContactUsSubject }> = [
    {
        key: ContactUsSubject.ACCOUNT_ENQUIRY,
        label: 'Account or General Enquiry',
        value: ContactUsSubject.ACCOUNT_ENQUIRY
    },
    {
        key: ContactUsSubject.FUEL_CARDS,
        label: 'Fuel Cards Enquiry',
        value: ContactUsSubject.FUEL_CARDS
    },
    {
        key: ContactUsSubject.REIMBURSEMENT,
        label: 'Reimbursement/s Enquiry',
        value: ContactUsSubject.REIMBURSEMENT
    },
    {
        key: ContactUsSubject.REFINANCE_VEHICLE,
        label: 'Refinance Vehicle Enquiry',
        value: ContactUsSubject.REFINANCE_VEHICLE
    },
    {
        key: ContactUsSubject.NEW_VEHICLE_ENQUIRY,
        label: 'New Vehicle Enquiry',
        value: ContactUsSubject.NEW_VEHICLE_ENQUIRY
    },
    {
        key: ContactUsSubject.MOVING_EMPLOYER,
        label: 'Moving Employer',
        value: ContactUsSubject.MOVING_EMPLOYER
    },
    {
        key: ContactUsSubject.VEHICLE_PAYOUT,
        label: 'Vehicle Payout Enquiry',
        value: ContactUsSubject.VEHICLE_PAYOUT
    },
    {
        key: ContactUsSubject.INSURANCE,
        label: 'Insurance Enquiry',
        value: ContactUsSubject.INSURANCE
    },
    {
        key: ContactUsSubject.OTHER,
        label: "Other Unlisted Enquiry Type",
        value: ContactUsSubject.OTHER
    }
]

export const StateSelectOptions: Array<{ key: number, label: string, value: string }> = [
    {
        key: 1,
        label: 'New South Wales',
        value: 'NSW'
    },
    {
        key: 2,
        label: 'Victoria',
        value: 'VIC'
    },
    {
        key: 3,
        label: 'Queensland',
        value: 'QLD'
    },
    {
        key: 4,
        label: 'Western Australia',
        value: 'WA'
    },
    {
        key: 5,
        label: 'South Australia',
        value: 'SA'
    },
    {
        key: 6,
        label: 'Tasmania',
        value: 'TAS'
    },
    {
        key: 7,
        label: 'Australian Capital Territory',
        value: 'ACT'
    },
    {
        key: 8,
        label: 'Northern Territory',
        value: 'NT'
    }
]