import { Box, Grid, useTheme, Table, TableBody, TableHead, TableRow, TableCell, Tab, Typography } from "@mui/material";
import { LinearProgressGauge } from "./LinearProgressGauge";
import { Cell } from "../../styled";
import { IBudgetItem } from "../../../constants/interfaces";

interface GaugeProps {
    data: Array< any >;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

export const BudgetUsageIndicators = ({ data, xsmobile, mobile, tablet, laptop, desktop }: GaugeProps ) => {
    const colors = useTheme().palette;
    const valueFormatter = ( value: number | null ) => `${ value?.toFixed( 2 ) }%`;
    
    const formattedData = data.map(( item: IBudgetItem ) => {
        const used = item.PcentSpent;
        const remaining = 100 - used;
        
        return {
            key: item.CostName,
            label: item.CostName,
            used: used,
            remaining: remaining,
            total: item.TotalBudget,
            proRataTotal: item.BudgetProrata,
            spent: item.TotalSpent
        }
    }).sort(( a, b ) => a.label.localeCompare( b.label ));

    return (
        <Grid  
            sx={{
                paddingLeft: '1rem',
                paddingRight: '0.5rem',
                marginTop: '-4.5rem'
            }}
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="tableHeader">Expense Category</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="tableHeader">Yearly Budget</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="tableHeader">Pro-rata Budget</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="tableHeader">Amount Spent</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="tableHeader">Budget Usage</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        formattedData.map(( item, index ) => (
                            <TableRow>
                                <Cell>
                                    <Typography variant="tableValue">{ item.label }</Typography>
                                </Cell>
                                <Cell>
                                    <Typography variant="tableValue">{ item.total }</Typography>
                                </Cell>
                                <Cell>
                                    <Typography variant="tableValue">{ item.proRataTotal }</Typography>
                                </Cell>
                                <Cell>
                                    <Typography variant="tableValue">{ item.spent }</Typography>
                                </Cell>
                                <Cell>
                                    <LinearProgressGauge
                                        key={ index }
                                        value={ item.used }
                                    />
                                </Cell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Grid>
    )
}


// interface UsageGaugeProps {
//     title: string;
//     valueUsed: number;
//     size: string;
// }

// export const UsageGauge = ({ title, valueUsed, size }: UsageGaugeProps) => (
//     <Box 
//         sx={{ 
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center',
//             width: '100%', 
//             height: size,
//         }}
//     >
//         <Gauge
//             value={ valueUsed }
//             startAngle={ -110 }
//             endAngle={ 110 }
//             sx={{
//                 [ `& .${ gaugeClasses.valueText }` ]: {
//                     fontSize: '1rem',
//                     transform: 'translate(0px, 0px)'
//                 }
//             }}
//             text={
//                 ({ value }) => `${ value?.toFixed( 0 ) }%`
//             }
//         />
//         <Typography variant="body1">{ title }</Typography>
//     </Box>
// )