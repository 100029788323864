import { PaletteMode, Palette, lighten, darken, alpha,  } from "@mui/material";
import createPalette, { PaletteOptions, dark, light } from "@mui/material/styles/createPalette";
import { tokens } from "./ColorTokens"

export default function GeneratePalette( mode: PaletteMode ): Palette {
    const colors = tokens( mode )

    const palette: PaletteOptions = {
        mode: mode,
        ...( mode === 'dark'
            ? {
                tokens: colors,
                common: {
                    black: "#000",
                    white: "#EEE",
                    elecBlue: colors.elecBlue[ 200 ],
                    softLight: colors.softLight[ 200 ],
                    skyCyan: colors.skyCyan[ 200 ],
                    sunflare: colors.sunflare[ 200 ],
                    vortex: colors.vortex[ 200 ],
                    ecoMint: colors.ecoMint[ 200 ],
                    vibeRed: colors.vibeRed[ 200 ],
                    carbon: colors.carbon[ 200 ]
                },
                primary: {
                    main: colors.elecBlue[ 200 ],
                    light: "#9AC3FF",
                    dark: "#0D2C8E",
                    contrastText: "#EEE"
                },
                secondary: {
                    main: alpha( colors.skyCyan[ 200 ], 0.24 ),
                    light: "#F5B87E",
                    dark: "#A8360D",
                    contrastText: "#EEE"
                },
                text: {
                    primary: colors.softLight[ 200 ],
                    secondary: "#999999",
                    disabled: "rgba(255, 255, 255, 0.3)"
                },
                borders: {
                    color: alpha( colors.softLight[ 500 ], 0.16 ),
                    hover: alpha( colors.softLight[ 500 ], 0.25 )
                },
                background: {
                    appbar: lighten( "#3e424b", 0.1 ),
                    default: "#121212", // "#121212" recommended by MUI
                    backgroundOverlay: alpha( colors.elecBlue[ 200 ], 0.08 ),
                    content: lighten( "#3e424b", 0.1 ),
                    footer: lighten( "#3e424b", 0.1 ),
                    
                },
                overlays: {
                    level1: alpha( colors.softLight[ 500 ], 0.05 ),
                    level2: alpha( colors.softLight[ 500 ], 0.07 ),
                    level3: alpha( colors.softLight[ 500 ], 0.09 ),
                    level4: alpha( colors.softLight[ 500 ], 0.12 ),
                    level6: alpha( colors.softLight[ 500 ], 0.14 ),
                    level8: alpha( colors.softLight[ 500 ], 0.16 ),
                    level12: alpha( colors.softLight[ 500 ], 0.18 ),
                    level16: alpha( colors.softLight[ 500 ], 0.20 ),
                    level24: alpha( colors.softLight[ 500 ], 0.24 )
                },
                dataGroup: {
                    main: "#0D2C8E",
                    light: "#9AC3FF",
                    dark: "#0D2C8E",
                    borderColor: darken( "#29b6f6", 0.3 ),
                    borderHoverColor: "#29b6f6",
                    contrastText: "#EEE"
                },
                dataGroupIconButtons: {
                    editIcon: "#00FF7F", // Spring Green
                    addIcon: "#00FF7F", // Medium Slate Blue
                    saveIcon: "#00FF7F", // Khaki
                    cancelIcon: "#CD5C5C", // Indian Red
                    deleteIcon: "#F08080" // Light Coral
                },
                inputs: {
                    label: colors.softLight[ 200 ],
                    focus: colors.elecBlue[ 200 ],
                    hover: colors.elecBlue[ 300 ],
                    border: {
                        color: colors.softLight[ 300 ],
                        hover: "#000000",
                        before: "#000000",
                        after: "#000000"
                    }
                },
                success: {
                    main: colors.ecoMint[ 200 ],
                    light: "#9AC3FF",
                    dark: "#0D2C8E",
                    contrastText: "#EEE"
                },
                info: {
                    main: colors.skyCyan[ 200 ],
                    light: "#9AC3FF",
                    dark: "#0D2C8E",
                    contrastText: "#EEE"
                },
                warning: {
                    main:  colors.sunflare[ 200 ],
                    light: "#9AC3FF",
                    dark: "#0D2C8E",
                    contrastText: "#EEE"
                },
                error: {
                    main: colors.vibeRed[ 200 ]
                },
                contrastThreshold: 15.8,
                tonalOffset: 0.2
            } : {
                tokens: colors,
                common: {
                    black: "#000",
                    white: "#EEE",
                    elecBlue: colors.elecBlue[ 500 ],
                    softLight: colors.softLight[ 500 ],
                    skyCyan: colors.skyCyan[ 500 ],
                    sunflare: colors.sunflare[ 500 ],
                    vortex: colors.vortex[ 500 ],
                    ecoMint: colors.ecoMint[ 500 ],
                    vibeRed: colors.vibeRed[ 500 ],
                    carbon: colors.carbon[ 500 ]
                },
                primary: {
                    main: colors.elecBlue[ 500 ],
                    light: "#9AC3FF",
                    dark: "#0D2C8E",
                    contrastText: "#EEE"
                },
                secondary: {
                    main: colors.softLight[ 500 ],
                    light: "#F5B87E",
                    dark: "#A8360D",
                    contrastText: colors.carbon[ 500 ]
                },
                text: {
                    primary: colors.carbon[ 500 ],
                    secondary: colors.carbon[ 400 ],
                    disabled: "rgba(0, 0, 0, 0.38)"
                },
                borders: {
                    color: colors.skyCyan[ 100 ],
                    hover: colors.skyCyan[ 500 ]
                },
                background: {
                    appbar: darken( "#FFF", 0.1 ),
                    default: "rgba( 240, 250, 250, 1 )",
                    backgroundOverlay: alpha( colors.softLight[ 500 ], 0.5 ),
                    content: lighten( "#f0f0fa", 0.9 ),
                    footer: "rgba( 5, 60, 255, 1 )",
                    //paper: "#e1e1e1"
                },
                overlays: {
                    level1: alpha( "#fff", 0.05 ),
                    level2: alpha( colors.softLight[ 500 ], 0.07 ),
                    level3: alpha( colors.softLight[ 500 ], 0.08 ),
                    level4: alpha( colors.softLight[ 500 ], 0.09 ),
                    level6: alpha( colors.softLight[ 500 ], 0.11 ),
                    level8: alpha( colors.softLight[ 500 ], 0.12 ),
                    level12: alpha( colors.softLight[ 500 ], 0.14 ),
                    level16: alpha( colors.softLight[ 500 ], 0.15 ),
                    level24: alpha( colors.softLight[ 500 ], 0.16 )
                },
                dataGroup: {
                    main: "#0D2C8E",
                    light: "#9AC3FF",
                    dark: "#0D2C8E",
                    borderColor: colors.vortex[300],
                    borderHoverColor: colors.vortex[500],
                    contrastText: "#EEE"
                },
                dataGroupIconButtons: {
                    editIcon: "#2E8B57", // Sea Green
                    addIcon: "#2E8B57", // Cornflower Blue
                    saveIcon: "#2E8B57",   // Light Goldenrod Yellow
                    cancelIcon: "#B22222",   // Firebrick
                    deleteIcon: "#DC143C" // Crimson
                },
                inputs: {
                    label: colors.carbon[ 500 ],
                    focus: colors.elecBlue[ 500 ],
                    hover: colors.vortex[ 300 ],
                    border: {
                        color: "#000000",
                        hover: "#000000",
                        before: "#000000",
                        after: "#000000"
                    }
                },
                info: {
                    main: colors.skyCyan[ 500 ],
                    light: "#9AC3FF",
                    dark: "#0D2C8E",
                    contrastText: "#EEE"
                },
                warning: {
                    main: colors.sunflare[ 500 ]
                },
                success: {
                    main: colors.ecoMint[ 600 ]
                },
                error: {
                    main: colors.vibeRed[ 500 ]
                },
                contrastThreshold: 4.5,
                tonalOffset: 0.2
            }
        )
    }

    return createPalette( palette ); 
}

// Amazing Green: #16fabc
// Blue: #29b6f6