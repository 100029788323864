import React from "react";
import { Typography, Box, Stack, useTheme } from "@mui/material";
import { LoginFormContainer, SlideContainer, FadeContainer } from "../../components/styled";
import { StepperBlue, MFAInput } from "../../components/UI";
import { CredentialsForm } from "./CredientialsForm";
import { MFALoader } from "../../components/UI";

interface LoginFormProps {
    username: string;
    password: string;
    usernameError: boolean;
    passwordError: boolean;
    mfaError: boolean;
    mfaResponse: number;
    helpText: string;
    mfaHelpText: string;
    showPassword: boolean;
    showPassClick: () => void;
    setPassword: ( v: string ) => void;
    setUsername: ( v: string ) => void;
    onLogin: () => void;
    onResetPassword: () => void;
    onMfaCompleted: ( v: string ) => void;
    currentView: 'credentials' | 'mfa' | 'complete';
    currentStep: number;
}

export const LoginForm = ({
    username,
    password,
    usernameError,
    passwordError,
    mfaError,
    mfaResponse,
    helpText,
    showPassword,
    showPassClick,
    mfaHelpText,
    setPassword,
    setUsername,
    onLogin,
    onResetPassword,
    onMfaCompleted,
    currentView,
    currentStep
}: LoginFormProps ) => {
    const theme = useTheme();
    const steps: Array< string > = [ 'Login', 'Multi-Factor Authentication', 'Access Granted' ]
    
    const handlekeyPress = ( event: React.KeyboardEvent ) => {
        if ( event.key === 'Enter' ) {
            onLogin()
        }
    }

    return(
        <LoginFormContainer className="desktop-login-form">
            <Stack sx={{ width: '100%', height: '100%' }}>
                
                <FadeContainer>
                    <Typography variant="h3">
                        Welcome to <span style={{ color: theme.palette.common.elecBlue }}>AutoDash</span>
                    </Typography>
                    
                    <Box
                        style={{
                            alignContent: 'flex-start',
                            width: '100%',
                            paddingTop: '2rem'
                        }}
                    >
                        <Typography variant="h4">Effortless access, anywhere, anytime</Typography>
                    </Box>
                </FadeContainer>
                
                <StepperBlue steps={ steps } activeStep={ currentStep } viewport="desktop" />
                
                <SlideContainer className="Slide-Container">

                    <CredentialsForm
                        currentView={ currentView }
                        username={ username }
                        password={ password }
                        usernameError={ usernameError }
                        passwordError={ passwordError }
                        setUsername={ setUsername }
                        setPassword={ setPassword }
                        onLogin={ onLogin }
                        onResetPassword={ onResetPassword }
                        onKeyDown={ ( e ) => handlekeyPress( e ) }
                        helpText={ helpText }
                        showPassword={ showPassword }
                        showPassClick={ showPassClick }
                        theme={ theme }
                    />

                    <MFAInput
                        length={ 6 }
                        view={ currentView }
                        error={ mfaError }
                        helpText={ mfaHelpText }
                        onCompletion={ ( v ) => onMfaCompleted( v ) }
                    />
                            
                    <MFALoader
                        minMilliSeconds={ 1000 }
                        mfaResponse={ mfaResponse }
                        currentView={ currentView }
                    />

                </SlideContainer>

            </Stack>
        </LoginFormContainer>
    )
}