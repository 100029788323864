import { useTheme } from '@mui/material';
import { ThumbUpIcon, ThumbDownIcon } from '../Icons';
import { ToolTipWrapper } from '../ToolTipWrapper';


export const InputMatchIndicator = ( { match, usePad }: { match: boolean, usePad?: boolean } ) => {
    const isDark = useTheme().palette.mode === 'dark'
    return (
        <div
            style={{
                paddingRight: usePad ? '1rem' : '0',
            }}
        >
            <ToolTipWrapper 
                title={ match ? 'Inputs Match' : 'Inputs Do Not Match' }
                placement='right-end'
                children={ match 
                    ? <ThumbUpIcon size='small' color='success' outlined={ isDark } />
                    : <ThumbDownIcon size='small' color='error' outlined={ isDark } />
                }
                
            />
        </div>
    )
}
