import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppStatusCodes, HttpMethods, MFA_Reason, Action, ErrorCodes } from '../constants/enums';
import { HttpLoadingHandler } from '../utils';
import { IPreferences, IReimbursmentSubmission, IResults } from '../constants/interfaces';
import {
    IUserPhone,
    IUserEmail,
    IUserAddress,
    IAddBankAccount
} from '../constants/interfaces';
import { actionInterceptor } from '../utils';


const abortedAction: IResults = {
    success: false,
    code: ErrorCodes.ACTION_ABORTED,
    message: 'Um sorry, I had an unexpected error occur. Please check any relevant inputs if applicable and try again.',
    payload: null
}

const heldAction: IResults = {
    success: false,
    code: AppStatusCodes.STEP_UP_REQUIRED,
    payload: null
}

// Authentication Thunks
export const login = createAsyncThunk( Action.LOGIN, async ( data: { email: string, password: string }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.POST, '/auth/login', Action.LOGIN, data, thunkAPI )
)

export const loginMFA = createAsyncThunk( Action.LOGIN_MFA_VERIFICATION, async ( data: { MFACode: string }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.POST, '/auth/verify', Action.LOGIN_MFA_VERIFICATION, data, thunkAPI )
)

export const userLogout = createAsyncThunk( Action.LOGOUT, async ( data: null, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.GET, '/auth/logout', Action.LOGOUT, null, thunkAPI )
)

// Data Fetching Thunks
export const getUserDetails = createAsyncThunk( Action.FETCH_PROFILE, async ( data: { ContactID: number }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.POST, '/contact/profile', Action.FETCH_PROFILE, data, thunkAPI )
)

export const getBasicPackageDetail = createAsyncThunk( Action.FETCH_BASIC_PACKAGE_INFO, async ( data: { ContactID: number }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.POST, `/benefit/basic`, Action.FETCH_BASIC_PACKAGE_INFO, data, thunkAPI )
)

export const getFullPackageDetail = createAsyncThunk( Action.FETCH_FULL_PACKAGE_INFO, async ( data: { ContactID: number, BenefitID: number }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.POST, `/benefit/package`, Action.FETCH_FULL_PACKAGE_INFO, data, thunkAPI )
)

export const getPackageLeaseAccDetail = createAsyncThunk( Action.FETCH_ACCOUNT_DETAIL, async ( data: { BenefitID: number }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.POST, `/benefit/benefitAccount`, Action.FETCH_ACCOUNT_DETAIL, data, thunkAPI )
)

export const getBalanceHistory = createAsyncThunk( Action.FETCH_BALANCE_HISTORY, async ( data: { BenefitID: number }, thunkAPI ) =>
    await HttpLoadingHandler(HttpMethods.POST, `/benefit/balanceHistory`, Action.FETCH_BALANCE_HISTORY, data, thunkAPI )
)

export const fetchDocuments = createAsyncThunk( Action.FETCH_DOCUMENTS, async ( ContactID: number, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.GET, `/financial/files/${ ContactID }`, Action.FETCH_DOCUMENTS, null, thunkAPI )
)

export const getReimbursementHistory = createAsyncThunk( Action.FETCH_REIMBURSEMENT_HISTORY, async ( ContactID: number, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.GET, `/financial/reimbursement/${ ContactID }`, Action.FETCH_REIMBURSEMENT_HISTORY, null, thunkAPI )
)

export const fetchBankAccounts = createAsyncThunk( Action.FETCH_BANK_ACCOUNTS, async ( data: { ContactID: number, ActiveOnly: number }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.GET, `/financial/bankAccounts/${ data.ContactID }/${ data.ActiveOnly }`, Action.FETCH_BANK_ACCOUNTS, null, thunkAPI )
)

export const fetchTransactionHistory = createAsyncThunk( Action.FETCH_TRANSACTION_HISTORY, async ( BenefitID: number, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.GET, `/benefit/transactionHistory/${ BenefitID }`, Action.FETCH_TRANSACTION_HISTORY, null, thunkAPI )
)

export const fetchBudgetData = createAsyncThunk( Action.FETCH_BUDGET_DATA, async ( data: { ContactID: number, BenefitID: number }, thunkAPI ) =>
    await HttpLoadingHandler( HttpMethods.GET, `/benefit/budget/${ data.ContactID }/${ data.BenefitID }`, Action.FETCH_BUDGET_DATA, data, thunkAPI )
)

// Add Record Thunks
export const submitNewReimbursement = createAsyncThunk( Action.SUBMIT_NEW_REIMBURSEMENT, async ( data: IReimbursmentSubmission, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.SUBMIT_NEW_REIMBURSEMENT, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.POST, `/financial/reimbursement`, Action.SUBMIT_NEW_REIMBURSEMENT, data, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

export const addNewPhone = createAsyncThunk( Action.ADD_NEW_PHONE, async ( data: IUserPhone , thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.ADD_NEW_PHONE, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.POST, `/contact/phone`, Action.ADD_NEW_PHONE, data, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

export const addNewEmail = createAsyncThunk( Action.ADD_NEW_EMAIL, async ( data: IUserEmail, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.ADD_NEW_EMAIL, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.POST, `/contact/email`, Action.ADD_NEW_EMAIL, data, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

export const addNewAddress = createAsyncThunk( Action.ADD_NEW_ADDRESS, async ( data: IUserAddress, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.ADD_NEW_ADDRESS, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.POST, `/contact/address`, Action.ADD_NEW_ADDRESS, data, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

export const addNewBankAccount = createAsyncThunk( Action.ADD_NEW_BANK_ACCOUNT, async ( data: IAddBankAccount, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.ADD_NEW_BANK_ACCOUNT, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.POST, `/financial/bankAccounts/add`, Action.ADD_NEW_BANK_ACCOUNT, data, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

// Data Update Thunks
export const updatePreferredName = createAsyncThunk( Action.UPDATE_PREFERRED_NAME, async ( data: { ContactID: number, PrefName: string }, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.UPDATE_PREFERRED_NAME, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.PATCH, `/contact/prefName`, Action.UPDATE_PREFERRED_NAME, data, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

export const updatePhone = createAsyncThunk( Action.UPDATE_PHONE, async ( data: IUserPhone, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.UPDATE_PHONE, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.PUT, `/contact/phone`, Action.UPDATE_PHONE, data, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

export const updateEmail = createAsyncThunk( Action.UPDATE_EMAIL, async ( data: IUserEmail, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.UPDATE_EMAIL, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.PUT, `/contact/email`, Action.UPDATE_EMAIL, data, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

export const updateAddress = createAsyncThunk( Action.UPDATE_ADDRESS, async ( data: { ContactID: number, UpdatedAddress: IUserAddress }, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.UPDATE_ADDRESS, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.PUT, `/contact/address`, Action.UPDATE_ADDRESS,  data, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

export const updatePreferences = createAsyncThunk( Action.UPDATE_PREFERENCES, async ( data: { UserID: string, updatedPrefs: IPreferences }, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.UPDATE_PREFERENCES, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.PUT, `/system/preferences`, Action.UPDATE_PREFERENCES, data, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

// Data Delete Thunks
export const deletePhone = createAsyncThunk( Action.DELETE_PHONE, async ( data: { ContactID: number, PhoneID: number }, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.DELETE_PHONE, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.DELETE, `/contact/phone/${ data.ContactID }/${ data.PhoneID }`, Action.DELETE_PHONE, {}, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

export const deleteEmail = createAsyncThunk( Action.DELETE_EMAIL, async ( data: { ContactID: number, EmailID: number }, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.DELETE_EMAIL, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.DELETE, `/contact/email/${ data.ContactID }/${ data.EmailID }`, Action.DELETE_EMAIL, {}, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})

export const deleteBankAccount = createAsyncThunk( Action.DELETE_BANK_ACCOUNT, async ( data: { ContactID: number, AccID: number }, thunkAPI ) => {
    const riskCheck = await actionInterceptor( Action.DELETE_BANK_ACCOUNT, data, thunkAPI )
    switch ( riskCheck ) {
        case 'Proceed':
            return await HttpLoadingHandler( HttpMethods.DELETE, `/financial/bankAccounts/${ data.ContactID }/${ data.AccID }`, Action.DELETE_BANK_ACCOUNT, {}, thunkAPI )
        case 'Abort':
            return abortedAction
        case 'Hold':
            return heldAction
        ;
    }
})