import * as React from 'react';
import { useTheme } from '@mui/material';
import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

interface MediaQueryProps {
    direction: 'up' | 'down'
    breakPoint: 'xsmobile' | 'mobile' | 'tablet' | 'laptop' | 'desktop'
}

export function useMediaWidth({ direction, breakPoint }: MediaQueryProps) {
    const theme: Theme = useTheme();
    const matchesUp = useMediaQuery( theme.breakpoints.up( breakPoint ));
    const matchesDown = useMediaQuery( theme.breakpoints.down( breakPoint ));
    
    return direction === 'up' ? matchesUp : matchesDown;
}

// export function useMediaWidth({ ...props }) {
//     const  = useMediaQuery('(min-width:600px)');

//     return <span>{`(min-width:600px) matches: ${matches}`}</span>;
// }
