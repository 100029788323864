import React, { useState } from 'react';
import { BasicButton } from './Button_Basic';
import { SaveIcon, CancelIcon } from './Icons';
import { DEContainer, DEContent, DETitle, DEActions } from '../styled';
import { Box } from '@mui/material';

type DialogProps = {
    show: boolean;
    title: string;
    confirmLabel?: string;
    cancelLabel?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    slideDirection: 'up' | 'down' | 'left' | 'right',
    children?: React.ReactNode;
}

// const Transition = ( slideDirection: 'up' | 'down' | 'left' | 'right' ) => React.forwardRef(function Transition(
//     props: TransitionProps & SlideProps & DialogProps & {
//         children: React.ReactElement<any, any>
//     },
//     ref: React.Ref<unknown>
//   ) {
//     return <Slide direction={ slideDirection } ref={ ref } { ...props } />;
// });
  
export function Dialog_DetailEdit({ children, ...props }: DialogProps ) {
    const { show, onConfirm, onCancel, title, slideDirection, confirmLabel, cancelLabel } = props

    return (

        <DEContainer
            open={ show }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DETitle display='flex' justifyContent='flex-start'>
                { title }
            </DETitle>
            <DEContent>
                { children }
            </DEContent>
            <DEActions>
                {
                    onConfirm && !onCancel && (
                        <Box sx={{
                                flexGrow: 1,
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'center', 
                                alignItems: 'center' 
                            }}
                        >
                            <BasicButton 
                                onClickHandler={ onConfirm } 
                                text={ confirmLabel ? confirmLabel : 'Add' }
                                iconPosition='start'
                                icon={ <SaveIcon outlined={ false } color='action' size='small' /> }
                            />
                        </Box>
                    )

                }
                {
                    onCancel && !onConfirm && (
                        <Box sx={{
                                flexGrow: 1,
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'center', 
                                alignItems: 'center' 
                            }}
                        >
                            <BasicButton 
                                onClickHandler={ onCancel } 
                                text={ cancelLabel ? cancelLabel : 'Cancel' }
                                iconPosition='start'
                                icon={ <CancelIcon outlined={ false } color='warning' size='small' /> }
                            />
                        </Box>
                    )
                }
                {
                    onConfirm && onCancel && (
                        <React.Fragment>
                            <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <BasicButton 
                                    onClickHandler={ onCancel } 
                                    text={ cancelLabel ? cancelLabel : 'Cancel' }
                                    iconPosition='start'
                                    icon={ <CancelIcon outlined={ false } color='warning' size='small' /> }
                                />
                            </Box>
                            <Box sx={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <BasicButton 
                                    onClickHandler={ onConfirm } 
                                    text={ confirmLabel ? confirmLabel : 'Add' }
                                    iconPosition='start'
                                    icon={ <SaveIcon outlined={ false } color='info' size='small' /> }
                                />
                            </Box>
                        </React.Fragment>
                    )
                }
            </DEActions>
        </DEContainer>

    );
}