import { Theme, useTheme } from '@mui/material';
import { AddressType } from '../../constants/enums';
import { IAdressDetails } from '../../constants/interfaces';


export const stateAcronymConverter = ( state: string ) => {
    switch ( state ) {
        case 'NSW':
            return 'New South Wales'
        case 'Nsw':
            return 'New South Wales'
        case 'nsw':
            return 'New South Wales'
        case 'VIC':
            return 'Victoria'
        case 'Vic':
            return 'Victoria'
        case 'vic':
            return 'Victoria'
        case 'QLD':
            return 'Queensland'
        case 'Qld':
            return 'Queensland'
        case 'qld':
            return 'Queensland'
        case 'WA':
            return 'Western Australia'
        case 'Wa':
            return 'Western Australia'
        case 'wa':
            return 'Western Australia'
        case 'SA':
            return 'South Australia'
        case 'Sa':
            return 'South Australia'
        case 'sa':
            return 'South Australia'
        case 'TAS':
            return 'Tasmania'
        case 'Tas':
            return 'Tasmania'
        case 'tas':
            return 'Tasmania'
        case 'ACT':
            return 'Australian Capital Territory'
        case 'Act':
            return 'Australian Capital Territory'
        case 'act':
            return 'Australian Capital Territory'
        case 'NT':
            return 'Northern Territory'
        case 'Nt':
            return 'Northern Territory'
        case 'nt':
            return 'Northern Territory'
        default:
            return state
    }
}

export const addressTypeIDConverter = ( typeID: AddressType, IsCurrent: boolean ) => {
    switch ( typeID ) {
        case AddressType.HOME:
            return IsCurrent ? 'Home Address' : 'Previous Home Address'
        case AddressType.MAIL:
            return IsCurrent ? 'Shipping Address' : 'Previous Shipping Address'
        case AddressType.BILLING:
            return IsCurrent ? 'Billing Address' : 'Previous Billing Address'
        case AddressType.BUSINESS:
            return IsCurrent ? 'Business Address' : 'Previous Business Address'
        default:
            return typeID
    }
}

/// Address Formater \\\
interface formatOptions {
    includeCountry?: boolean;
    customConcatLabel?: string;
    returnObject?: boolean;
}

export const formatAddress = ( format: 'concatenate' | 'deconstruct', address: IAdressDetails, options?: formatOptions ): any => {
    try {
        switch ( format ) {
            case 'concatenate': {
                switch ( options?.returnObject === false ) {
                    case true:
                        return !options?.includeCountry ? [
                            {
                                key: address.AddressID.toString(),
                                label: options?.customConcatLabel ? options.customConcatLabel : addressTypeIDConverter( address.AddressTypeID, address.IsCurrent ),
                                value: `${ address.StreetAddress }, ${ address.City }, ${ stateAcronymConverter( address.State ) }, ${ address.Postcode }`,
                                isEdit: true
                            }] : [{
                                key: address.AddressID.toString(),
                                label: options?.customConcatLabel ? options.customConcatLabel : addressTypeIDConverter( address.AddressTypeID, address.IsCurrent ),
                                value: `${ address.StreetAddress }, ${ address.City }, ${ stateAcronymConverter( address.State ) }, ${ address.Postcode }, Australia`,
                                isEdit: true
                            }
                        ]
                    case false:
                        return !options?.includeCountry ? {
                                key: address.AddressID.toString(),
                                label: options?.customConcatLabel ? options.customConcatLabel : addressTypeIDConverter( address.AddressTypeID, address.IsCurrent ),
                                value: `${ address.StreetAddress }, ${ address.City }, ${ stateAcronymConverter( address.State ) }, ${ address.Postcode }`,
                                isEdit: true
                            } : {
                                key: address.AddressID.toString(),
                                label: options?.customConcatLabel ? options.customConcatLabel : addressTypeIDConverter( address.AddressTypeID, address.IsCurrent ),
                                value: `${ address.StreetAddress }, ${ address.City }, ${ stateAcronymConverter( address.State ) }, ${ address.Postcode }, Australia`,
                                isEdit: true
                        }    
                }
            }
            case 'deconstruct': {
                switch ( options?.returnObject === false ) {
                    case true:
                        return !options?.includeCountry ? [
                            {
                                key: address.AddressID.toString() + 'addressType',
                                label: 'Address Type',
                                value: addressTypeIDConverter( address.AddressTypeID, address.IsCurrent ),
                                isEdit: true
                            },
                            {
                                key: address.AddressID.toString() + 'streetAddress',
                                label: 'Street Address',
                                value: address.StreetAddress,
                                isEdit: true
                            },
                            {
                                key: address.AddressID.toString() + 'city',
                                label: 'Suburb/City',
                                value: address.City,
                                isEdit: true
                            },
                            {
                                key: address.AddressID.toString() + 'state',
                                label: 'State',
                                value: stateAcronymConverter( address.State ),
                                isEdit: true
                            },
                            {
                                key: address.AddressID.toString() + 'postcode',
                                label: 'Postcode',
                                value: address.Postcode,
                                isEdit: true
                            }
                        ] : [
                                {
                                    key: address.AddressID.toString() + 'addressType',
                                    label: 'Address Type',
                                    value: addressTypeIDConverter( address.AddressTypeID, address.IsCurrent ),
                                    isEdit: true
                                },
                                {
                                    key: address.AddressID.toString() + 'streetAddress',
                                    label: 'Street Address',
                                    value: address.StreetAddress,
                                    isEdit: true
                                },
                                {
                                    key: address.AddressID.toString() + 'city',
                                    label: 'Suburb/City',
                                    value: address.City,
                                    isEdit: true
                                },
                                {
                                    key: address.AddressID.toString() + 'state',
                                    label: 'State',
                                    value: stateAcronymConverter( address.State ),
                                    isEdit: true
                                },
                                {
                                    key: address.AddressID.toString() + 'postcode',
                                    label: 'Postcode',
                                    value: address.Postcode,
                                    isEdit: true
                                },
                                {
                                    key: address.AddressID.toString() + 'country',
                                    label: 'Country',
                                    value: 'Australia',
                                    isEdit: false
                                }
                        ]
                    case false:
                        return !options?.includeCountry ? {
                            addressType: {
                                key: address.AddressID.toString() + 'addressType',
                                label: 'Address Type',
                                value: addressTypeIDConverter( address.AddressTypeID, address.IsCurrent ),
                                isEdit: true
                            },
                            streetAddress: {
                                key: address.AddressID.toString() + 'streetAddress',
                                label: 'Street Address',
                                value: address.StreetAddress,
                                isEdit: true
                            },
                            city: {
                                key: address.AddressID.toString() + 'city',
                                label: 'Suburb/City',
                                value: address.City,
                                isEdit: true
                            },
                            state: {
                                key: address.AddressID.toString() + 'state',
                                label: 'State',
                                value: stateAcronymConverter( address.State ),
                                isEdit: true
                            },
                            postcode: {
                                key: address.AddressID.toString() + 'postcode',
                                label: 'Postcode',
                                value: address.Postcode,
                                isEdit: true
                            }
                        } : {
                            addressType: {
                                key: address.AddressID.toString() + 'addressType',
                                label: 'Address Type',
                                value: addressTypeIDConverter( address.AddressTypeID, address.IsCurrent ),
                                isEdit: true
                            },
                            streetAddress: {
                                key: address.AddressID.toString() + 'streetAddress',
                                label: 'Street Address',
                                value: address.StreetAddress,
                                isEdit: true
                            },
                            city: {
                                key: address.AddressID.toString() + 'city',
                                label: 'Suburb/City',
                                value: address.City,
                                isEdit: true
                            },
                            state: {
                                key: address.AddressID.toString() + 'state',
                                label: 'State',
                                value: stateAcronymConverter( address.State ),
                                isEdit: true
                            },
                            postcode: {
                                key: address.AddressID.toString() + 'postcode',
                                label: 'Postcode',
                                value: address.Postcode,
                                isEdit: true
                            },
                            country: {
                                key: address.AddressID.toString() + 'country',
                                label: 'Country',
                                value: 'Australia',
                                isEdit: false
                            }
                        }
                    }
                }
            default:
                return null
        }
    } catch ( error ) {
        console.error( 'Switch Error: ', error )
    }
}