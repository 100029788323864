import React, { useState, useEffect, } from "react";
import { Snackbar, SnackbarCloseReason, Typography, Slide, useTheme, useMediaQuery } from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import { useAppSelector, useAppDispatch } from "../../redux/Store";
import { removeAlert } from "../../redux/slicers/AppState";
import { AppAlert } from "../styled";

interface IState {
    id: number;
    type: any;
    message: string;
    open: boolean;
}

const SlideIn = ( 
    props: TransitionProps & { children: React.ReactElement< any, any > } 
) => <Slide { ...props } appear enter exit direction="up" />

const SlideDown = (
    props: TransitionProps & { children: React.ReactElement< any, any > }
) => <Slide { ...props } appear enter exit direction="down" />

export const AppSnackbar = () => {
    const { alerts } = useAppSelector( state => state.AppState );
    const [ expanded, setExpanded ] = useState< boolean >( false );
    const [ anchor, setAnchor ] = useState<{ 
        vertical: 'top' | 'bottom', horizontal: 'left' | 'center' | 'right' 
    }>({ vertical: 'bottom', horizontal: 'right' });
    const [ state, setState ] = useState< Array< IState >>( alerts.map(( alert ) => ({
        id: alert.id,
        type: alert.type,
        message: alert.message,
        open: true
    }))); 
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isTablet = useMediaQuery( theme.breakpoints.down( 'laptop' ));
    const isMobile = useMediaQuery( theme.breakpoints.down( 'tablet' ));
    const isDark = theme.palette.mode === 'dark';

    useEffect(() => {
        switch ( true ) {
            case isTablet && !isMobile:
                setAnchor({ vertical: 'top', horizontal: 'center' });
                break;
            case isTablet && isMobile:
                setAnchor({ vertical: 'top', horizontal: 'left' });
                break;
            default:
                setAnchor({ vertical: 'bottom', horizontal: 'right' });
        }
    }, [ isTablet, isMobile ]);

    useEffect(() => {
        setState(( prevState ) => {
            const newState = [ ...prevState ];
            alerts.forEach(( alert ) => {
                const found = newState.find( item => item.id === alert.id );
                if ( !found ) {
                    newState.push({ id: alert.id, type: alert.type, message: alert.message, open: true });
                }
            });
            return newState;
        });
    }, [ alerts ]);

    const handleClose = ( id: number ) => {
        console.log( id );
        setState( prevState => prevState.map( alert => { return alert.id === id ? { ...alert, open: false  } : { ...alert } } ));
        setTimeout(() => {
            dispatch( removeAlert( id ));
        }, 500);
    }

    const handleAutoClose = ( reason: SnackbarCloseReason, id: number ) => {
        const alertType: any = alerts.find( alert => alert.id === id )?.type ? alerts.find( alert => alert.id === id )?.type : 'info';
        if (( alertType === 'warning' || alertType === 'error' ) && reason === 'clickaway' ) return;
        setState( prevState => prevState.map( alert => { return alert.id === id ? { ...alert, open: false  } : { ...alert } } ));
        setTimeout(() => {
            dispatch( removeAlert( id ));
        }, 500);
    };

    return (
        <React.Fragment>
            { state.map(( alert, index ) => (
                <Snackbar
                    key={ alert.id }
                    autoHideDuration={ alert.type === 'error' || alert.type === 'warning' ? 4500 : 3000 } // 3 or 4.5 seconds
                    open={ alert.open }
                    onClick={ () => handleClose( alert.id ) }
                    onClose={ ( e, r ) => handleAutoClose( r, alert.id ) }
                    anchorOrigin={ anchor }
                    sx={{
                        marginBottom: isMobile || isTablet ? '0' : `${ index * 60 }px`,
                        marginTop: isMobile || isTablet ? `${ index * 60 }px` : '0' 
                    }}
                    TransitionComponent={ isMobile ? SlideDown : SlideIn }
                >
                    <AppAlert
                        ismobile={ `${ isMobile }` }
                        severity={ alert.type }
                        type={ alert.type }
                        variant={ isDark ? 'outlined' : 'filled' }
                    >
                        <span
                            style={{
                                whiteSpace: expanded ? 'normal' : 'nowrap',
                                overflow: expanded ? 'visible' : 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                                cursor: 'pointer'
                            }}
                        >
                            <Typography variant="body1Bold">{ alert.message }</Typography>
                        </span>
                    </AppAlert>
                </Snackbar>
            ))}
        </React.Fragment>
    )
}