
const fixBase64Padding = ( base64: string ): string => {
    while (base64.length % 4 !== 0) {
        base64 += '=';
    }
    return base64;
}

const base64ToArrayBuffer = ( base64: string ) => {
    const ficedBase64 = fixBase64Padding( base64 )
    const binaryString = window.atob( ficedBase64 )
    const binaryLen = binaryString.length
    const bytes = new Uint8Array( binaryLen )
    for ( let i = 0; i < binaryLen; i++ ) {
        bytes[i] = binaryString.charCodeAt( i )
    }
    return bytes.buffer
}

const isGzipped = ( arrayBuffer: ArrayBuffer ): boolean => {
    const uint8Array = new Uint8Array( arrayBuffer )
    return uint8Array[0] === 0x1F && uint8Array[1] === 0x8B
}

const decompressArrayBuffer = async ( arrayBuffer: ArrayBuffer ): Promise< ArrayBuffer > => {
    const stream = new DecompressionStream( 'gzip' );
    const compressedStream = new Response( arrayBuffer ).body
    if (!compressedStream) {
        throw new Error('Unable to create compressed stream from array buffer');
    }
    const decompressedStream = compressedStream.pipeThrough( stream );
    const decompressedArrayBuffer = await new Response( decompressedStream ).arrayBuffer();
    return decompressedArrayBuffer;
}

const mimeTypeSwitch = ( fileType: string ) => {
    let mimeType;
    switch ( fileType ) {
        case 'jpg':
        case 'jpeg':
            return mimeType = 'image/jpeg';
        case 'pdf':
            return mimeType = 'application/pdf';
        case 'txt':
            return mimeType = 'text/plain';
        case 'png':
            return mimeType = 'image/png';
        case 'svg':
            return mimeType = 'image/svg+xml';
        case 'gif':
            return mimeType = 'image/gif';
        case 'rar':
            return mimeType = 'application/vnd.rar';
        case '7z':
            return mimeType = 'application/x-7z-compressed';
        case 'bmp':
            return mimeType = 'image/bmp';
        case 'csv':
            return mimeType = 'text/csv';
        case 'xlsx':
            return mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case 'xls':
            return mimeType = 'application/vnd.ms-excel';
        case 'doc':
            return mimeType = 'application/msword';
        case 'docx':
            return mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case 'heic':
            return mimeType = 'image/heic';
        case 'json':
            return mimeType = 'application/json';
        case 'xml':
            return mimeType = 'application/xml';
        case 'zip':
            return mimeType = 'application/zip';
        case 'octet-stream':
            return mimeType = 'application/octet-stream';
        default:
            return new Error( 'File type not supported' )
        ;
    }
}

/*  !!! Fun Facts With Brad: 
    Steer stores files uploaded internally with the content gzipped,
    where if a file is related to a reimbursement it is stored in standard zip format. 
    Makes sense they're both files but related to different things. So clearly we need to 
    store them in different compression formats.

    So if you're reading this and you don't want a headache, dont remove the seemingly pointless check for gzip.
*/
export const fileDownload = async ( base64: string, fileName: string, fileType: string ) => {
    try {
        const arrayBuffer = base64ToArrayBuffer( base64 )
        const isGzip = isGzipped( arrayBuffer ) // Check if the firt two bytes are 0x1F & 0x8B & handle accordingly
        let decompressedArrayBuffer;
        isGzip ? 
            decompressedArrayBuffer = await decompressArrayBuffer( arrayBuffer ) 
            : 
            decompressedArrayBuffer = arrayBuffer
        ;

        let mimeType = mimeTypeSwitch( fileType )
        if ( mimeType instanceof Error ) {
            return new Error( 'File type not supported' );
        }

        const blob = new Blob([ decompressedArrayBuffer ], { type: mimeType })
        fileName = fileName.replace( ' ', '_' )
        fileName = fileName.replace( '.', '' )
        fileName = fileName.replace( '/', '' )

        const url = URL.createObjectURL( blob )
        const link = document.createElement( 'a' )
        link.href = url
        link.download = fileName
        document.body.appendChild( link )
        link.click()
        document.body.removeChild( link )
        URL.revokeObjectURL( url )
        return;
    } catch ( error ) {
        return new Error( 'Failed to download file' )
    }
}