import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useAppSelector } from '../../redux/Store';


export function LoadingIndicator() {
    const { loading } = useAppSelector(( state ) => state.AppState );
    const [ showIndicator, setShowIndicator ] = useState< boolean >( false );
    const indicatorTimeout = 500; // Minimum duration to show the indicator

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if ( loading ) {
            setShowIndicator( true );
        } else {
            timer = setTimeout( () => {
                if ( !loading ) {
                    setShowIndicator( false );
                }
            }, indicatorTimeout );
        }

        return () => clearTimeout( timer );

    }, [ loading ])

    return (
        <Backdrop open={ showIndicator }>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}