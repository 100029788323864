import React, { useState, useEffect } from "react";
import { ResetPassword } from "./ResetPassword";
import { useAppDispatch } from "../../redux/Store";
import { validatePassword, validateMfaCode, validateEmail } from "../../utils/commons";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutePaths, AppStatusCodes } from "../../constants/enums";
import { useMediaWidth } from "../../hooks";
import { addAlert } from "../../redux/slicers/AppState";
import { resetPassword, verifyResetMfa, setPassword } from "../../redux/AppStateThunks";
import { mapErrorMessages } from "../../utils/validation";

// Error Maps
const userErrorMap = {
    email: ''
}
const mfaErrorMap = {
    mfaCode: ''
}
const passwordErrorMap = {
    newPasswd: '',
    confirmPasswd: ''
}


export default function PasswordReset() {
    const [ view, setView ] = useState< 'user' | 'mfa' | 'verify' | 'reset' | 'complete' >( 'user' )
    const [ currentStep, setCurrentStep ] = useState< number >( 0 )
    const [ username, setUsername ] = useState< string >( '' )
    const [ newPassword, setNewPassword ] = useState< string >( '' )
    const [ confirmPassword, setConfirmPassword ] = useState< string >( '' )
    const [ validPassword, setValidPassword ] = useState< boolean >( true )
    const [ inValidMessage, setInValidMessage ] = useState< string >( '' )
    const [ showPassword, setShowPassword ] = useState< boolean >( false )
    const [ showConfirmPassword, setShowConfirmPassword ] = useState< boolean >( false )
    // Errors
    const [ helperText, setHelperText ] = useState< string >( '' )
    const [ usernameError, setUsernameError ] = useState< boolean >( false )
    const [ passwordError, setPasswordError ] = useState< boolean >( false )
    const [ mfaError, setMfaError ] = useState< boolean >( false )

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isTablet = useMediaWidth({ direction: 'down', breakPoint: 'tablet' })
    

    useEffect(() => {
        switch( view ) {
            case 'user':
                setCurrentStep( 0 )
                break
            case 'mfa':
                setCurrentStep( 1 )
                break
            case 'verify':
                setCurrentStep( 1 )
                break
            case 'reset':
                setCurrentStep( 2 )
                break
            case 'complete':
                setCurrentStep( 3 )
                break
            default:
                setCurrentStep( 0 )
        }
    }, [ view ])

    useEffect(() => {
        const criteriaMessage: string = isTablet ? 
            'Criteria: min 10 chars, upper & lowercase, numbers, & special char'
            :
            'Password must include numbers, lower & uppercase, special characters, and minumum 10 characters'
        ;
        const newLength: boolean = newPassword.length >= 10;
        const conLength: boolean = confirmPassword.length >= 10;
        const criteriaNew: boolean = validatePassword( newPassword )
        const criteriaCon: boolean = validatePassword( confirmPassword )
        const strictMatch: boolean = newPassword === confirmPassword

        if ( !newLength ) {
            setValidPassword( true )
            setInValidMessage( '' )
            return;
        }

        if ( !criteriaNew ) {
            setValidPassword( false )
            setInValidMessage( criteriaMessage )
            return
        }  
        if ( criteriaNew ) {
            setValidPassword( true )
            setInValidMessage( '' )
        }
        
        if ( !conLength ) return;
            
        
        if ( !criteriaCon ) {
            setValidPassword( false )
            setInValidMessage( criteriaMessage )
            return
        }  
        if ( criteriaCon ) {
            setValidPassword( true )
            setInValidMessage( '' )
        }

        if ( !strictMatch ) {
            setValidPassword( false )
            setInValidMessage( 'Passwords do not match' )
            return
        }

        setValidPassword( true )
        setInValidMessage( '' )
    },[ newPassword, confirmPassword ])

    async function onSubmitUsername() {
        if ( !validateEmail( username )) {
            setUsernameError( true )
            setHelperText( 'Please enter a valid email address' )
            return
        }
        setUsernameError( false )
        setHelperText( '' )

        const { success, code, payload } = await dispatch( resetPassword({ email: username })).unwrap()
        if ( success && code === AppStatusCodes.MFA_REQUIRED ) {
            setView( 'mfa' )
            return
        }
        if ( !success && code === AppStatusCodes.USER_LOCKED_OUT ) {
            setUsernameError( true )
            setHelperText( 'User is locked out. Please contact support' )
            return
        }
        if ( !success && code === AppStatusCodes.USER_NOT_FOUND ) {
            setUsernameError( true )
            setHelperText( `Cannot reset password for ${ username }` )
            return
        }
        if ( !success && code === AppStatusCodes.DATA_VALIDATION_JOI ) {
            const errors: any = mapErrorMessages( payload, userErrorMap )
            setUsernameError( true )
            setHelperText( errors?.email )
            return
        }
        dispatch( addAlert({ id: Date.now(), message: 'An unexpected error occurred', type: 'error' }))
    }

    async function onSubmitMFA( mfaCode: string ) {
        if ( !validateMfaCode( mfaCode )) {
            setMfaError( true )
            setHelperText( 'Please enter a valid 6-digit code' )
            return
        }
        setMfaError( false )
        setHelperText( '' )

        const { success, code, payload } = await dispatch( verifyResetMfa({ MFACode: mfaCode })).unwrap()
        if ( success && code === AppStatusCodes.PASSWORD_RESET_APPROVED ) {
            dispatch( addAlert({ id: Date.now(), message: 'Password reset approved', type: 'success' }))
            setView( 'reset' )
            return
        }
        if ( !success && code === AppStatusCodes.MFA_INVALID_CODE ) {
            setMfaError( true )
            setHelperText( 'Invalid code. Please try again' )
            return
        }
        if ( !success && code === AppStatusCodes.DATA_VALIDATION_JOI ) {
            const errors: any = mapErrorMessages( payload, mfaErrorMap )
            setMfaError( true )
            setHelperText( errors?.mfaCode )
        }
        dispatch( addAlert({ id: Date.now(), message: 'An unexpected error occurred', type: 'error' }))
    }

    async function onSubmitNewPassword() {
        if ( newPassword !== confirmPassword ) {
            setPasswordError( true )
            setHelperText( 'Passwords do not match' )
            return
        }
        if ( !validatePassword( newPassword )) {
            setPasswordError( true )
            setHelperText( 'Password does not meet the complexity criteria' )
            return
        }
        const { success, code, payload } = await dispatch( setPassword({ newPasswd: newPassword, confirmedPasswd: confirmPassword })).unwrap()
        if ( success && code === AppStatusCodes.PASSWORD_RESET_SUCCESS ) {
            setView( 'complete' )
            dispatch( addAlert({ id: Date.now(), message: 'Password reset successful', type: 'success' }))
            navigate( RoutePaths.LOGIN )
            return
        }
        if ( !success ) {
            switch( code ) {
                case AppStatusCodes.PASSWORD_COMPLEXITY_FAILURE:
                    setPasswordError( true )
                    setView( 'reset' )
                    setHelperText( 'Password does not meet the complexity criteria' )
                    return
                case AppStatusCodes.PASSWORD_REUSE_DETECTED:
                    setPasswordError( true )
                    setView( 'reset' )
                    setHelperText( 'You have used this password previously. Please choose a new password' )
                    return
                case AppStatusCodes.NEWPASS_MISMATCH:
                    setPasswordError( true )
                    setView( 'reset' )
                    setHelperText( 'Passwords do not match, please re-enter the password' )
                    return
                case AppStatusCodes.DATA_VALIDATION_JOI: {
                    const errors: any = mapErrorMessages( payload, passwordErrorMap )
                    console.log( 'passwd errors: ', errors )
                    setPasswordError( true )
                    setHelperText( errors?.newPasswd )
                    return
                }
                default:
                    dispatch( addAlert({ id: Date.now(), message: 'An unexpected error occurred', type: 'error' }))
                    navigate( RoutePaths.LOGIN )
                    return
            }
        }
        dispatch( addAlert({ id: Date.now(), message: 'An unexpected error occurred', type: 'error' }))
        navigate( RoutePaths.LOGIN )
    }

    const cancelReset = () => navigate( RoutePaths.LOGIN, { state: { from: location.pathname }, replace: true } )

    const handleVisibility = ( v: 'new' | 'conf' ) => {
        if ( v === 'new' ) {
            setShowPassword( !showPassword )
        }
        if ( v === 'conf' ) {
            setShowConfirmPassword( !showConfirmPassword )
        }
    }
    
    return (
        <ResetPassword 
            currentView={ view }
            currentStep={ currentStep }
            username={ username }
            helpText={ helperText }
            setUsername={ setUsername }
            newPassword={ newPassword }
            setNewPassword={ setNewPassword }
            confirmPassword={ confirmPassword }
            setConfirmPassword={ setConfirmPassword }
            isValid={ validPassword }
            inValidMessage={ inValidMessage }
            submitUsername={ onSubmitUsername }
            submitMFA={ onSubmitMFA }
            submitNewPassword={ onSubmitNewPassword }
            onCancel={ cancelReset }
            showPassword={ showPassword }
            showConfirmPassword={ showConfirmPassword }
            showPasswordClick={ handleVisibility }
            // Errors
            emailError={ usernameError }
            passwordError={ passwordError }
            mfaError={ mfaError }
        />
    )
}