import { IFAQs } from "../../constants/interfaces"

export const FAQs: Array< IFAQs > = [
    {
        key: 1000,
        open: false,
        question: "How is fuel purchased through my novated lease?",
        answer: `
            <p>You should have received your choice of fuel cards at the commencement of your lease.</p>
            <p>
                You have the option of: 
                <ul>
                    <li><b>BP Card</b></li>
                    <li><b>Ampol Card</b></li>
                    <li><b>Shell Card</b></li>
                </ul>
            </p>
            <p>It should be noted that various discounts have been negotiated and are passed on in full toeach novated lease holder in line with the fuel expenditure.</p>
        `
    },
    {
        key: 2000,
        open: false,
        question: `How do I replace my tyres?`,
        answer: `
            <p>In order to provide an efficient and widely accepted tyre purchasing service InsideEDGE utilised the Smart Fleet network. The Smart Fleet network includes most tyre retailers and service centres providing tyres. When tyres are required, the car is booked into the chosen tyre outlet, identifying the car as part of the Smart Fleet network; the outlet will then contact Smart Fleet for authorisation prior to tyres being fitted. This ensures the correct tyres are fitted in line with manufacturer’s specification. This also ensures fleet pricing is provided ensuring no overcharging occurs.</p>
        `
    },
    {
        key: 3000,
        open: false,
        question: `How is scheduled servicing and maintenance managed under my novated lease?`,
        answer: `
            <p>InsideEDGE utilise the Smart Fleet network. This network represents all authorised manufacturer service outlets, tyre and windscreen replacement outlets and most recognised service centres.</p>
            <ul>
                <li>When the car requires servicing as per the manufacturer handbook, the car is booked into the drivers preferred authorised service centre and advises the vehicle is managed by InsideEDGE Novated Leasing via the Smart Fleet program</li>
                <li>The service centre will seek service and maintenance authorisation via Smart Fleet on 1300 780 097 prior to commencing any work on the vehicle; this is to protect against over servicing and over charging and reduces the risk of paying for items which would otherwise be covered under warranty or insurance (in the case of a windscreen replacement)</li>
            </ul>
            <p>This is applicable for all servicing, general maintenance (including batteries) and tyre replacements and repairs.</p>
        `
    },
    {
        key: 4000,
        open: false,
        question: `What car operating expenses are paid for by the driver and reimbursed by InsideEDGE?`,
        answer: `
            <ul>
                <li><b>Annual Registration/CTP renewal</b></li>
                <li><b>Comprehensive Insurance Annual premium (only if not using InsideEDGE insurance policy)</b></li>
                <li><b>Fuel costs while waiting for fuel card/s to be issued</b></li>
                <li><b>Insurance excess *</b></li>
                <li><b>Driver incurred fines</b></li>
            </ul>
            <p>
                <b>Registration: </b>
                <br>
                Under a novated lease, as the car is owned by and registered in the lease holder’s name, the registration renewal will be sent to the lease holder’s home address. 
                In order to ensure the car remains registered at all times, the lease holder must pay the premium and seek reimbursement from InsideEDGE via the reimbursement form found at: <a href="https://iedge.com.au" target="_blank">www.iedge.com.au</a>.
                <br>
                It should be noted reimbursement of insurance premiums are paid once sufficient funds are held in the individual novated lease holder account.
            </p>
            <p>
                <b>Comprehensive Insurance: </b>
                <br>
                For lease holders who have chosen to arrange their own insurance, the policy and premium will be sent to the lease holders home address, this lease holder must pay the premium and seek reimbursement from InsideEDGE via the reimbursement form found at: <a href="https://iedge.com.au" target="_blank">www.iedge.com.au</a>.
                <br>
                It should be noted reimbursement of insurance premiums are paid once sufficient funds are held in the individual novated lease holder account.
            <p>
            <p>
                <b>Fuel Costs: </b>
                <br>
                Once a novated lease is established the chosen fuel cards are ordered with the car’s registration number for identification purposes, fuel not purchased with the fuel cards can be reimbursed by InsideEDGE using the reimbursement form found at: <a href="https://iedge.com.au" target="_blank">www.iedge.com.au</a>.
            <p>
            <p>
                <b>*Insurance excess: </b>
                <br>
                At the commencement of a Novated Lease the anticipated car costs are discussed and calculated, a provision for an insurance excess is not included, however in the unfortunate event of an accident requiring an insurance claim and the payment of an excess, 
                the excess may be claimed if there is sufficient surplus funds or if an adjustment is made to increase the deductions. Once sufficient funds have accumulated the excess can be reimbursed using the INSIDE EDGE reimbursement form found at: <a href="https://iedge.com.au" target="_blank">www.iedge.com.au</a>.
            <p>
        `
    },
    {
        key: 5000,
        open: false,
        question: `What if payroll deductions are not matching actual costs?`,
        answer: `
            <p>It is unusual for this to not be the case, unless there is a change in driver or driving circumstances, such as a change in kilometres being travelled due to a move in home or office location.</p>

            <p>In the event payroll deductions are not matching expenses in either surplus or deficit a re-costing of budgets may be necessary.   Re-costings can be instigated by the novated lease holder or by InsideEDGE, depending on the circumstances.</p>

            <p>InsideEDGE will contact novated lease holders where overspending of budgeted expenses is occurring (commonly fuel and maintenance). Generally, this is as a result of underestimating the kilometres driven per year or a change in driving patterns and/or circumstances.</p>

            <p>InsideEDGE monitors the costs and balance to make sure maximum tax benefits are being achieved without accruing negative balances within the lease account.</p>
        `
    },
    {
        key: 6000,
        open: false,
        question: `Is a log book required to track kilometres under a novated lease?`,
        answer: `
            <p>Only in circumstances of high business use and through consultation with a novated lease specialist it may be determined the keeping of a log book will deliver the best financial outcome.</p>
        `
    },
    {
        key: 7000,
        open: false,
        question: `How is FBT calculated?`,
        answer: `
            <p>Under FBT legislation there are two methods used to calculate the potential FBT associated with novated leases, the “Statutory Formula Method” and the “Operating Cost Method”.</p>
            <p>
                <b>Statutory Formula Method: </b>
                <br>
                The most common method for calculating FBT on a novated lease car is the “<u>Statutory Formula</u>". This method is used for a majority of novated leases as most employees do not use their car for business purposes. 
                In the event the employee has no business use or occasional business use the Statutory Formula provides tax savings and no log book or kilometer justification is required.
            </p>
            <p>
                <b>Operating Cost Method: </b>
                <br>
                In the event an employee uses their car for business purposes the Operating Cost Method may deliver the best financial outcome, the operating cost method requires a log book to be kept for 12 weeks to establish and confirm the percentage of business use. This percentage is 
                then applied to the total operating costs of the car and the FBT is calculated on the “private” portion of the costs. InsideEDGE will consult with each employee to provide information as to the most effective way to establish each novated lease. It should be noted that driving 
                to and from work is not considered “business use”.
            </p>
            <p>It should also be noted that as InsideEDGE are not the employee’s financial advisor, all information provided should be referred to the employee’s financial advisor to ensure all circumstances have been taken into consideration.</p>
        `
    },
    {
        key: 8000,
        open: false,
        question: `What happens to my novated lease if I take extended leave or go on maternity leave?`,
        answer: `
            <p>In the event an employee takes extended leave for whatever reason and is not being paid, the novation of the lease will cease and the employee will be responsible for the lease and the cars running costs.</p>
            <p>When extended leave is being planned, through contact with InsideEDGE, it is possible to increase deductions prior to the leave in order to prepare to meet the cars costs during the leave.</p>
            <p>At the recommencement of employment upon notification, the novation agreement will be reinstated and the benefits continue as prior to the leave.</p>
        `
    },
    {
        key: 9000,
        open: false,
        question: `What happens if I am leaving my place of employment?`,
        answer: `
            <p>If you are moving on from your current employer for any reason, it is essential InsideEDGE are notified prior to the departure. This will cancel the employer’s obligation and commence the finalisation of the novated lease account.</p>
            <p>
                InsideEDGE will assist with understanding your options and assist you to set up with appropriate action: 
                <ul>
                    <li><b>Re-novate the finance lease to a new employer</b></li>
                    <li><b>Continue with the finance lease in the employee’s name via direct debit</b></li>
                    <li><b>Sell the car and payout the lease</b></li>
                </ul>
                Once the novation agreement has been cancelled InsideEDGE will conduct a reconciliation of the actual costs compared to the payroll deductions collected. If there is a credit amount the amount will be paid to the previous employer who will pay the employee via payroll, 
                the amount is taxed at the employee’s marginal tax rate. Any negative balance or shortfall will need to be paid by the employee to InsideEDGE. The reconciliation may take up to 45 days to complete as outstanding fuel and maintenance invoices need to be received from all suppliers and vendors.
            </p>
        `
    },
    {
        key: 10000,
        open: false,
        question: `Re-novating the lease to a new employer`,
        answer: `
            <p>
                InsideEDGE will assist an employee wishing to re-Novate the lease to a new employer. If the new employer does not have an existing program, InsideEDGE will assist to implement and understand the benefits of a program to all of its employees if possible. In the event the employer has an existing 
                program InsideEDGE will assist to re-novate your lease to the existing program provider.
            </p>
        `
    },
    {
        key: 11000,
        open: false,
        question: `Continue with the Finance Lease in the employee’s name via direct debit`,
        answer: `
            <p>In the event an employee leaves their employment and does not have a new place of employment, InsideEDGE will provide a direct debit facility to enable the employee to continue to pay the monthly lease rental until new employment is found or for the remaining obligation of the lease.</p>
        `
    },
    {
        key: 12000,
        open: false,
        question: `Sell the car and payout the lease`,
        answer: `
            <p>The option to sell the car and payout a lease is always an option and InsideEDGE will assist with arranging payout figures on any lease upon request.</p>
            <p>
                It should be noted a lease agreement is a finance contract with a fixed rate for a fixed term and if a lease agreement is too be paid out depending at what point the termination is required the payout figure may be higher than the market value of the car, careful consideration 
                should be given to the option to pay out a lease before its “end of contract date”.
            </p>
        `
    },
    {
        key: 13000,
        open: false,
        question: `My lease is coming to the end what are my options and what do I need to do?`,
        answer: `
            <p>InsideEDGE will make contact at least four to six months prior to the end of a novated lease to discuss the alternatives available.</p>
            <p>
                The options available include:
                <ul>
                    <li>
                        <b>Refinancing/roll over the lease for a further period of time: </b>
                        If the employee wishes to retain the car for a further period of time at the conclusion of the original lease, InsideEDGE will assist the employee to refinance/rollover the residual value for a further lease period ranging from 1-5 year, depending on ownership intentions and age of car.
                    </li>
                    <li>
                        <b>Purchasing a new car and trade-in or sell the existing car: </b>
                        Prior to the end of an existing lease, InsideEDGE will assist with the purchase of a new car in the same manner as the establishment of the existing lease, while the existing lease has a residual value, InsideEDGE will negotiate to trade the car to the new car supplying dealer to ensure the maximum value is achieved, at the delivery of the new car the dealer will trade the 
                        existing car in, payout the residual value and pay any profit over and above the residual value to the employee, it should be noted in the event of the car not being worth the residual value the employee will need to make up the shortfall.
                        <br>
                        When InsideEDGE establishes a novated lease, InsideEDGE observes the minimum residual value allowable by the Aust Tax Office, under normal circumstances most employees will comfortably achieve the residual value. When the ATO minimum residual values are implemented any profit over and above the residual value is tax free to the employee.
                    </li>
                    <li>
                        <b>Payout the car: </b>
                        At the end of the lease the employee has the right to offer to purchase the car at the residual amount plus GST and own the car outright.
                    </li>
                </ul>
            </p>
        `
    }
]