import { DataGroup, DataField } from './';
import { IObjectKeyValues } from '../../constants/interfaces';

interface IAccountInformationProps {
    accountInfo: IObjectKeyValues;
    financeDetails: IObjectKeyValues;
    xsmobile?: number;
    mobile?: number;
    tablet?: number;
    laptop?: number;
    desktop?: number;
}

export function AccountInformation({ 
    accountInfo,
    financeDetails,
    xsmobile = 4, 
    mobile = 5, 
    tablet = 4, 
    laptop = 5,
    desktop = 6 
}: IAccountInformationProps ) {

    return (
        <DataGroup
            title='Account Information'
            className='accountInfo'
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
            {/* Block: Account Holder, Enployer, BenefitID */}
            <DataField
                data={ accountInfo.accountHolder }
                xsmobile={ 2 } mobile={ 3 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
            />
            <DataField
                data={ accountInfo.employer }
                xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 4 } desktop={ 4 }
            />
            <DataField
                data={ accountInfo.linkedPackage }
                xsmobile={ 2 } mobile={ 3 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
            />
            
            {/* Block: Financier, Contract Number, FBT Exempt */}
            <DataField
                data={ financeDetails.financier }
                xsmobile={ 2 } mobile={ 2 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
            />
            <DataField
                data={ financeDetails.contractNumber }
                xsmobile={ 2 } mobile={ 3 } tablet={ 2 } laptop={ 4 } desktop={ 4 }
            />
            <DataField
                data={ accountInfo.fbtExempt }
                xsmobile={ 2 } mobile={ 2 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
            />
            
            {/* Block: Start Date, End Date, FBT Held */}
            <DataField
                data={ financeDetails.startDate }
                xsmobile={ 2 } mobile={ 3 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
            />
            <DataField
                data={ financeDetails.endDate }
                xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 4 } desktop={ 4 }
            />
            <DataField
                data={ accountInfo.fbtHeldByIE }
                xsmobile={ 2 } mobile={ 3 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
            />

            {/* Block: Payment Frequency, Term, Payment Structure */}
            <DataField
                data={ financeDetails.paymentFrequency }
                xsmobile={ 2 } mobile={ 2 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
            />
            <DataField
                data={ financeDetails.paymentStructure }
                xsmobile={ 2 } mobile={ 3 } tablet={ 2 } laptop={ 4 } desktop={ 4 }
            />
            <DataField
                data={ financeDetails.term }
                xsmobile={ 2 } mobile={ 2 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
            />

            {/* Block: Payment ExGST, Payment GST, Payment IncGST */}
            <DataField
                data={ financeDetails.paymentExGST }
                xsmobile={ 2 } mobile={ 3 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
            />
            <DataField
                data={ financeDetails.paymentGST }
                xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 4 } desktop={ 4 }
            />
            <DataField
                data={ financeDetails.paymentIncGST }
                xsmobile={ 2 } mobile={ 3 } tablet={ 3 } laptop={ 3 } desktop={ 4 }
            />

        </DataGroup>
    )
}
