import React from "react"
import { styled, Input, FilledInput, OutlinedInput, TextField, InputLabel, Radio, FormControlLabel } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";

export const InputLabelStandard = styled( InputLabel )(({ theme }) => ({
    minWidth: '100%',
    color: theme.palette.inputs.label,
    '&.Mui-focused': {
        color: theme.palette.inputs.focus,
    },
    '&:after': {
        color: theme.palette.inputs.label
    },
    '&.Mui-disabled': {
        color: theme.palette.text.disabled
    }
}))

export const InputStandard = styled( Input )(({ theme, error }) => ({
    minWidth: '100%',
    border: 'none',
    borderBottom: `1px solid ${ theme.palette.inputs.border.color }`,
    backgroundColor: 'transparent',
    outline: 'none',
    '&.MuiInputBase-input': {
        fontFamily: 'TT Commons Pro',
        fontWeight: 900,
        fontSize: 12
    },
    '&:hover': {
        border: 'none',
        borderBottom: `1px solid ${ theme.palette.inputs.hover }`,
    },
    '&:before, &:after': {
        borderBottom: `none`, // Make sure these don't override your hover
        content: 'none',
        backgroundColor: 'transparent'
    },
    '&.Mui-focused': {
        borderBottom: `1px solid ${ theme.palette.inputs.focus }`,
        backgroundColor: 'transparent',
        outline: 'none'
    },
    '&.Mui-disabled': {
        borderBottom: `1px dotted ${ theme.palette.text.disabled }`,
        color: theme.palette.text.disabled,
        outline: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
    },
    '&:-webkit-autofill': {
        backgroundColor: 'transparent !important', // Or any desired background color
        WebkitBoxShadow: '0 0 0px 1000px white inset !important', // Reset autofill background in WebKit
        boxShadow: '0 0 0px 1000px white inset !important', // For non-webkit browsers
        color: 'inherit !important', // Ensure text color remains correct
    },
    '&:autofill': {
        backgroundColor: 'transparent !important', // Fallback for other browsers
    },
    ...( error && {
        borderBottom: `1px solid ${ theme.palette.error.main }`,
        '&:hover': {
            borderBottom: `1px solid ${ theme.palette.error.main }`
        },
        '&.Mui-focused': {
            borderBottom: `1px solid ${ theme.palette.error.main }`,
        }
    })
}))

export const FilledInputStandard = styled( FilledInput )(({ theme }) => ({
    minWidth: '100%',
}))

export const OutlinedInputStandard = styled( OutlinedInput )(({ theme }) => ({
    minWidth: '100%'
}))

export const TextAreaStandard = styled( TextField )(({ theme }) => ({
    minWidth: '100%',
    backgroundColor: 'transparent',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.inputs.border, // Custom border color
          borderWidth: '1px'
        },
        '&:hover fieldset': {
          borderColor: theme.palette.inputs.hover, // Custom hover border color
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.inputs.focus, // Border color when focused
          borderWidth: '1px'
        },
        '&.Mui-disabled fieldset': {
            borderColor: theme.palette.text.disabled,
            color: theme.palette.text.disabled,
            outline: 'none',
            borderStyle: 'dotted'
        },
    },

}))

export const RadioToggle = styled( Radio )(({ theme }) => ({
    '&.Mui-disabled': {
        color: theme.palette.text.disabled
    }
}))

export const RadioToggleLabel = styled( FormControlLabel )(({ theme }) => ({
    '&.Mui-disabled': {
        color: theme.palette.text.disabled
    }
}))

export const DateOnlySelector = styled( DatePicker )(({ theme }) => ({
    border: 'none',
    '& .MuiInputBase-root': {
        border: 'none',
        borderBottom: `1px solid ${ theme.palette.inputs.border.color }`,
        backgroundColor: 'transparent',
        outline: 'none',
        '&:hover': {
            borderBottom: `1px solid ${ theme.palette.inputs.hover }`,
        },
        '&.Mui-focused': {
            borderBottom: `1px solid ${ theme.palette.inputs.focus }`,
        },
        '&.Mui-disabled': {
            borderBottom: `1px dotted ${ theme.palette.text.disabled } !important`,
            color: theme.palette.text.disabled,
            outline: 'none',
            '&:hover': {
                borderBottom: `1px dotted ${ theme.palette.text.disabled } !important`,
            }
        },
    }
}))

export const DigitInput = styled( TextField )<{ 
    error: boolean, 
    view: 'credentials' | 'mfa' | 'complete' | 'code' | 'loading', 
    animationdelay: any,
    isDesktop?: boolean
}>(({ theme, error, view, animationdelay, isDesktop }) => ({
    width: '2.5rem',
    height: '2rem',
    marginRight: '1rem',
    '& input': {
        textAlign: 'center',
        fontSize: '1rem',
        paddingLeft: '0rem',
        paddingRight: '0rem',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: `1px solid ${ theme.palette.inputs.border.color }`, // Default border color
        },
        '&:hover fieldset': {
            border: `2px solid ${ theme.palette.inputs.border.hover }`, // Hover border color
        },
        '&.Mui-focused fieldset': {
            border: `2px solid ${ theme.palette.inputs.border.hover }`, // Focused border color
        },
        '&.Mui-disabled': {
            '&:hover fieldset': {
                border: `1px solid ${ theme.palette.text.disabled }`
            },
        }
    },
    ...( error && ( view === 'mfa' || view === 'code' ) && {
        animation: `vibrate 0.3s ease-in-out ${ animationdelay || 0 }s`,
    }),
    '@keyframes vibrate': {
        '0%': {
            transform: 'translateX(-2px)'
        },
        '10%': {
            transform: 'translateX(0)'
        },
        '20%': {
            transform: 'translateX(2px)'
        },
        '40%': {
            transform: 'translateX(0)'
        },
        '50%': {
            transform: 'translateX(-2px)'
        },
        '60%': {
            transform: 'translateX(0)'
        },
        '70%': {
            transform: 'translateX(2px)'
        },
        '80%': {
            transform: 'translateX(0)'
        },
        '90%': {
            transform: 'translateX(-2px)'
        },
        '100%': {
            transform: 'translateX(0)'
        }
    },
    [ theme.breakpoints.down( 'laptop' ) ]: {
        width: '2rem',
        height: '1.5rem',
        marginRight: '0.5rem',
        '& input': {
            fontSize: '0.75rem'
        }
    }
}))
