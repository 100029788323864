import React from "react"
import { IReimbursmentSubmission } from "../../../../constants/interfaces";
import { validateDate } from "../../../../utils/commons";
import { reimbursmentSubmissionSchema } from "../../../../utils/validation";

export function validateSubmission( submission: IReimbursmentSubmission ) {
    let errors: Array< string > = [];
    let isValid = true;


    const schema = reimbursmentSubmissionSchema;
    const { error } = schema.validate( submission );
    if ( error ) {
        errors.push( error.message );
        isValid = false;
    }

    if ( submission.BenefitID === 0 ) {
        errors.push( 'Please select a package to claim against' );
        isValid = false;
    }

    if ( submission.BenefitOperCostTypeID === 0 ) {
        errors.push( 'Please select the type of reimbursement' );
        isValid = false;
    }

    if ( !submission.AmountIncTax ) {
        errors.push( 'Please enter the amount to be reimbursed' );
        isValid = false;
    }

    if ( 
        parseInt( submission.AmountIncTax, 10 ) < 0 
        || parseInt( submission.AmountTax, 10 ) < 0 
        || parseInt( submission.AmountIncTax, 10 ) > 999999 
        || parseInt( submission.AmountTax, 10 ) > 999999 
    ) {
        errors.push( 'Please enter a valid amount for the reimbursement' );
        isValid = false;
    }

    if ( submission.PurchaseDate !== '' && validateDate( submission.PurchaseDate ) === false ) {
        errors.push( 'Please enter a valid date for the reimbursement' );
        isValid = false;
    }

    if ( typeof submission.BankAccountID === 'number' &&  submission.BankAccountID === 0 ) {
        errors.push( 'Please select the bank account to be reimbursed to' );
        isValid = false;
    }

    if ( submission.SupplierCompanyName === null ) {
        errors.push( 'Please select the supplier for the reimbursement' );""
        isValid = false;
    }

    if ( submission.IsNoReferenceNumber === false && ( submission.SupplierRefText === null || submission.SupplierRefText === '' ) ) {
        errors.push( 'Please enter the reference number for the reimbursement' );
        isValid = false;
    }

    if ( submission.Odometer === null ) {
        errors.push( 'Please enter the current odometer reading for your vehicle' );
        isValid = false;
    }

    return { isValid, errors };
}