import React, { useState, useEffect } from "react";
import { Grid, useTheme, Typography, Divider, AccordionSummary, AccordionDetails, useMediaQuery, Card } from "@mui/material";
import { DataField } from "../../components/UI/DataGroup";
import { DocumentsContent, AccordionMapped } from "../../components/styled";
import { columnBase } from '../../constants';
import { BasicButton } from "../../components/UI";
import { useAppDispatch, useAppSelector } from "../../redux/Store";
import { addAlert } from "../../redux/slicers/AppState";
import { fetchDocuments } from "../../redux/AccessThunks";
import { DocumentsHeader } from "./DocumentsHeader";
import { fileDownload } from "../../utils/commons";
import { DownloadIcon } from "../../components/UI/Icons";
import { ToolTipWrapper } from "../../components/UI";
import { formatDateTimeUTC_TZ } from "../../utils/commons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IMappedDocx {
    FileID: number;
    FileName: string;
    FileType: string;
    FileSize: string;
    UploadedDate: string;
    FileData: string;
    FileExtension: string;
    FileNameOnly: string;
    open: boolean;
}

export default function Documents() {
    const { session, documents } = useAppSelector(( state ) => state.Access )
    const [ docx, setDocx ] = useState< Array< IMappedDocx >>(
        documents.map(( file ) => ({
            FileID: file.FileID,
            FileType: file.FileType,
            FileName: file.FileFullName,
            FileNameOnly: file.FileNameOnly ? file.FileNameOnly : file.FileFullName,
            FileSize: file.FileSize,
            UploadedDate: file.FileUploadDate,
            FileExtension: file.FileExtension,
            FileData: file.FileData,
            open: false
        }))
    )
    const isLaptop = useMediaQuery( useTheme().breakpoints.down( 'desktop' ))
    const isDark = useTheme().palette.mode === 'dark'; 
    const dispatch = useAppDispatch()
    
    useEffect(() => {
        if ( documents.length === 0 && session.ContactID !== 0 ) {
            ( 
                async () => await dispatch( fetchDocuments( session.ContactID )).unwrap()
            )()
        }
    }, [] )

    useEffect(() => {
        setDocx( documents.map(( file ) => ({
            FileID: file.FileID,
            FileType: file.FileType,
            FileName: file.FileFullName,
            FileNameOnly: file.FileNameOnly ? file.FileNameOnly : file.FileFullName,
            FileSize: file.FileSize,
            UploadedDate: file.FileUploadDate,
            FileExtension: file.FileExtension,
            FileData: file.FileData,
            open: false
        })))
    }, [ documents ] )

    const toggleDocState = ( id: number ) => setDocx( prevDocx => prevDocx.map( doc => ({
        ...doc,
        open: doc.FileID === id ? !doc.open : false
    })))

    function handleFileDownload( fileData: string, fileName: string, fileExtension: string ) {
        const download = fileDownload( fileData, fileName, fileExtension )
        if ( download instanceof Error ) {
            dispatch( addAlert({ id: Date.now(), message: 'Error occurred downloading file', type: 'error' }) )
        } else {
            dispatch( addAlert({ id: Date.now(), message: 'File Downloaded Successfully', type: 'success' }) )
        }
    }

    return (
        <React.Fragment>

            <DocumentsHeader Title='Documents' Subtitle='The documentation we have on file can be found below' />

            <DocumentsContent className="Documentation">
                <Grid container
                    flexDirection='column'
                    justifyContent='center' 
                    alignItems='flex-start' 
                    columns={ columnBase }
                    p={ 1 }
                >
                    { docx.length > 0 && docx.map(( file, index ) => (
                        <Grid item
                            key={ file.FileID }
                            width={ '100%' }
                            onClick={ ( e ) => toggleDocState( file.FileID ) }
                            sx={{
                                marginTop: file.open && index !== 0 ? '1rem' : 0,
                                marginBottom: file.open && index !== docx.length - 1 ? '1rem' : 0,
                                transition: 'margin 0.5s ease-in-out'
                            }}
                            xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ 5 } desktop={ 6 }
                        >
                            <AccordionMapped
                                expanded={ file.open }
                                open={ file.open }
                                index={ index }
                                blocklength={ docx.length }
                            >
                                <AccordionSummary
                                    expandIcon={ <ExpandMoreIcon /> }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant='h6'>
                                        Document Type: { file.FileType }
                                    </Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails>
                                    <Grid container
                                        columns={ columnBase }
                                    >
                                        <DataField 
                                            data={{ key: 'FileID', label: 'File ID', value: file.FileID, isEdit: false }}
                                            xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 4 } desktop={ 2 }
                                        />

                                        <DataField 
                                            data={{ key: 'FileName', label: 'File Name', value: file.FileNameOnly, isEdit: false }}
                                            xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 4 } desktop={ 6 }
                                        />

                                        <Grid item xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 2 } desktop={ 4 } />

                                        <DataField 
                                            data={{ key: 'UploadedDate', label: 'Uploaded Date', value: formatDateTimeUTC_TZ( file.UploadedDate, true, false ), isEdit: false }}
                                            xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 4 } desktop={ 2 }
                                        />

                                        <DataField 
                                            data={{ key: 'FileType', label: 'File Type', value: file.FileExtension, isEdit: false }}
                                            xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 4 } desktop={ 3 }
                                        />

                                        <DataField 
                                            data={{ key: 'FileSize', label: 'File Size', value: file.FileSize, isEdit: false }}
                                            xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 4 } desktop={ 3 }
                                        />

                                        <Grid item
                                            sx={{ display: 'flex', justifyContent: isLaptop ? 'flex-end' : 'center', alignItems: 'flex-end', marginBottom: '0.3rem' }}
                                            xsmobile={ 4 } mobile={ 6 } tablet={ 8 } laptop={ 4 } desktop={ 4 }
                                        >
                                            <ToolTipWrapper title='Download File'
                                                placement="right"
                                                children={
                                                    <BasicButton
                                                        color='primary'
                                                        text="Download"
                                                        size="small"
                                                        disabled={ false }
                                                        onClickHandler={ () => handleFileDownload( file.FileData, `${ file.FileID }_${ file.FileName }`, file.FileExtension ) }
                                                        icon={ <DownloadIcon size="medium" outlined={ isDark } color="inherit" /> }
                                                        iconPosition="end"
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </AccordionMapped>
                        </Grid>
                    ))}

                    {
                        docx.length === 0 && (
                            <Card sx={{ padding: '1rem', width: '100%', textAlign: 'center' }}>
                                <Typography variant='h6'>
                                    (No documents found on file)
                                </Typography>
                            </Card>
                        )
                    }
                </Grid>
            </DocumentsContent>
        </React.Fragment>
    )
}