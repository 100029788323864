import React, { useState, useEffect } from 'react';
import { Grid, IconButton, useTheme, Box, Collapse, useMediaQuery, Typography } from '@mui/material';
import { IUserDetails, IUserPhone } from '../../../constants/interfaces';
import { DataGroup, DataFieldConditional, StandardTextInput, DataField, BasicDropDownMenu } from '../../../components/UI';
import { phoneTypeIDConverter, formatPhoneByType } from '../../../components/commonUIF';
import { AnimatedGridItem } from '../../../components/styled';
import { DeleteIcon } from '../../../components/UI/Icons';
import { useAppDispatch } from '../../../redux/Store';
import { addNewPhone } from '../../../redux/AccessThunks';
import { addAlert } from '../../../redux/slicers/AppState';
import { DeletePhoneDialog } from './DeletePhoneDialog';
import { columnBase } from '../../../constants';
import { AppStatusCodes, PhoneType } from '../../../constants/enums';
import { on } from 'events';


interface PhoneComponentProps {
    userDetails: IUserDetails;
    currentEdit: 'phone' | 'email' | 'address' | 'none';
    onShowAdd: () => void;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

export function PhoneDetails( { userDetails, xsmobile, mobile, tablet, laptop, desktop, ...props }: PhoneComponentProps ) {
    const { currentEdit, onShowAdd } = props
    const [ editPhone, setEditPhone ] = useState< 'view' | 'edit' | 'add' >( 'view' )
    const [ showAddPhone, setShowAddPhone ] = useState( false )
    const [ showDeleteConfirm, setShowDeleteConfirm ] = useState( false )
    const [ confirmDelete, setConfirmDelete ] = useState< IUserPhone | null >( null )
    const [ phoneErrors, setPhoneErrors ] = useState<{ select: boolean, textfield: boolean }>({ select: false, textfield: false })
    const [ helpText, setHelpText ] = useState( '' )
    const [ phoneArray, setPhoneArray ] = useState(
        userDetails?.PhoneNumbers 
        ? userDetails.PhoneNumbers.map(( phone ) => ({
            key: `${ phone.PhoneID }`,
            label: `${ phoneTypeIDConverter( phone.PhoneTypeID ) }` ,
            value: formatPhoneByType( phone.PhoneNumber, phone.PhoneTypeID ),
            isEdit: true
        })) : []
    )
    const [ newPhone, setNewPhone ] = useState< IUserPhone >({
        ContactID: userDetails.ContactID,
        PhoneID: 0,
        PhoneTypeID: PhoneType.MOBILE,
        PhoneType: '',
        PhoneNumber: ''
    })
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const isDarkMode = theme.palette.mode === 'dark'
    const isMobile = useMediaQuery( theme.breakpoints.down( 'tablet' ))

    useEffect(() => {
        setPhoneArray(
            userDetails?.PhoneNumbers 
            ? userDetails.PhoneNumbers.map(( phone ) => ({
                key: `${ phone.PhoneID }`,
                label: `${ phoneTypeIDConverter( phone.PhoneTypeID ) }`,
                value: formatPhoneByType( phone.PhoneNumber, phone.PhoneTypeID ),
                isEdit: true
            })) : []
        )
    }, [ userDetails.PhoneNumbers ])

    useEffect(() => {
        if ( userDetails.ContactID !== 0 ) setNewPhone({ ...newPhone, ContactID: userDetails.ContactID })
    }, [ userDetails.ContactID ])

    useEffect(() => {

        if ( currentEdit !== 'phone' ) onCancelChanges()
        
    }, [ currentEdit  ])

    const onAddPhone = async () => {
        setPhoneErrors({ select: false, textfield: false })
        setHelpText( '' )
        // Validation Logic Required \\
        if ( !newPhone.PhoneType || PhoneType[ newPhone.PhoneTypeID ] === '' ) {
            setPhoneErrors({ ...phoneErrors, select: true })
            setHelpText( 'Phone Type is required' )
            return
        }
        if ( newPhone.PhoneNumber === '' ) {
            setPhoneErrors({ ...phoneErrors, textfield: true })
            setHelpText( 'Phone Number is required' )
            return
        }
        if ( newPhone.PhoneNumber.length < 10 ) {
            setPhoneErrors({ ...phoneErrors, textfield: true })
            setHelpText( 'Phone Number must be at least 10 digits' )
            return
        }
        if ( newPhone.PhoneNumber.length > 10 ) {
            setPhoneErrors({ ...phoneErrors, textfield: true })
            setHelpText( 'Phone Number must be no more than 10 digits' )
            return
        }

        const add = await dispatch( addNewPhone( newPhone )).unwrap()
        const { success, code } = add
        if ( success && code === AppStatusCodes.SUCCESS ) {
            dispatch( addAlert({ id: Date.now(), message: `Phone added successfully`, type: 'success' }))
            setShowAddPhone( false )
            setEditPhone( 'view' )
            setNewPhone({
                ContactID: 0,
                PhoneID: 0,
                PhoneTypeID: PhoneType.MOBILE,
                PhoneType: '',
                PhoneNumber: ''
            })
            return 
        }
        if ( success && code === AppStatusCodes.VID_VERIFICATION_REQUIRED ) {
            setShowAddPhone( false )
            setEditPhone( 'view' )
            setNewPhone({
                ContactID: 0,
                PhoneID: 0,
                PhoneTypeID: PhoneType.MOBILE,
                PhoneType: '',
                PhoneNumber: ''
            })
            return
        }
        setShowAddPhone( false )
        setEditPhone( 'view' )
        setNewPhone({
            ContactID: 0,
            PhoneID: 0,
            PhoneTypeID: PhoneType.MOBILE,
            PhoneType: '',
            PhoneNumber: ''
        })
        dispatch( addAlert({ id: Date.now(), message: `Add phone failed`, type: 'error' }))
    }

    const onCancelChanges = () => {
        setShowAddPhone( false )
        setPhoneErrors({ select: false, textfield: false })
        setHelpText( '' )
        setNewPhone({
            ContactID: userDetails.ContactID,
            PhoneID: 0,
            PhoneTypeID: PhoneType.MOBILE,
            PhoneType: '',
            PhoneNumber: ''
        })
        setPhoneArray(
            userDetails?.PhoneNumbers 
            ? userDetails.PhoneNumbers.map(( phone ) => ({
                key: `${ phone.PhoneID }`,
                label: `${ phoneTypeIDConverter( phone.PhoneTypeID ) }`,
                value: formatPhoneByType( phone.PhoneNumber, phone.PhoneTypeID ),
                isEdit: true
            })) : []
        )
        setEditPhone( 'view' )
    }


    const handleClickDeleteIcon = ( phoneID: string ) => {
        let phone = userDetails.PhoneNumbers.find(( p ) => p.PhoneID === Number( phoneID ))
        if ( phone ) {
            setConfirmDelete( phone )
            setShowDeleteConfirm( true )
        }
    }

    const addPhoneCLick = async () => {
        onShowAdd()
        setShowAddPhone( true )
        setEditPhone( 'add' )
    }

    return (
        <DataGroup 
            title='Phone Numbers'
            subGroup
            isEditable
            hideBackground
            editing={ editPhone !== 'view' }
            onEditClick={ () => setEditPhone( editPhone === 'view' ? 'edit' : 'view' ) }
            onAddClick={ addPhoneCLick }
            onSaveClick={ onAddPhone }
            onCancelClick={ () => onCancelChanges() }
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
            {/* Below Grid Item opens downward when "showAddPhone" == true */}
            <Collapse in={ showAddPhone } unmountOnExit
                sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            >
                <AnimatedGridItem container
                    show={ showAddPhone === true ? 'true' : 'false' }
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        width: '100%',
                        paddingLeft: '0.9rem',
                        paddingRight: '0.4rem'
                    }}
                >
       
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '30%',
                            paddingRight: isMobile ? '0rem' : '0.5rem',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem'
                        }}
                    >
                        <BasicDropDownMenu
                            menuLabel='Phone Type'
                            selectID='phoneType'
                            size='small'
                            options={[
                                { key: 1, label: 'Mobile', value: PhoneType.MOBILE },
                                { key: 2, label: 'Home', value: PhoneType.HOME },
                                { key: 3, label: 'Work', value: PhoneType.BUSINESS },
                                { key: 4, label: 'Business Direct', value: PhoneType.BIZ_DIRECT },
                                { key: 5, label: 'Fax', value: PhoneType.FAX }
                            ]}
                            onChange={ ( v ) => setNewPhone({ ...newPhone, PhoneTypeID: v, PhoneType: phoneTypeIDConverter( v )  }) }
                            error={ phoneErrors.select === true }
                        />
                    </Box>

                    <Box
                        sx={{
                            width: isMobile ? '100%' : '70%',
                            paddingLeft: isMobile ? '0rem' : '0.5rem',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem'
                        }}
                    >
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='Phone Number'
                            placeholder='Add Phone Number'
                            value={ newPhone.PhoneNumber }
                            type='text'
                            disabled={ !showAddPhone }
                            onChange={ ( value ) => {
                                let cleanVal = value.replace( /[^0-9]/g, '' );
                                cleanVal = cleanVal.slice( 0, 10 );
                                setNewPhone({ ...newPhone, PhoneNumber: cleanVal })
                            }}
                            error={ phoneErrors.textfield === true }
                        />
                    </Box>

                    <Typography variant='caption' sx={{ color: theme.palette.error.main }}>
                        { phoneErrors && helpText }
                    </Typography>

                </AnimatedGridItem>
            </Collapse>
           
            { phoneArray ? (
                phoneArray.map(( phone ) => (
                    <DataFieldConditional
                        key={ phone.key }
                        condition={ editPhone === 'view' }
                        data={ phone }
                        desktop={ phoneArray.length > 2 ? 4 : phoneArray.length < 2 ? 12 : 6 }
                        xsmobile={ 4 } mobile={ 5 } tablet={ 8 } laptop={ phoneArray.length > 1 ? 5 : 10 } 
                    >
                        <Box
                            display='flex'
                            flexDirection='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Box width={ `90%` }>
                                <DataField data={ phone } />
                            </Box>

                            <Box 
                                flexGrow={ 1 }
                                display='flex'
                                flexDirection='row'
                                justifyContent='center'
                            >
                                <IconButton
                                    size='medium'
                                    onClick={ () => handleClickDeleteIcon( phone.key ) }
                                    sx={{
                                        color: theme.palette.dataGroupIconButtons.deleteIcon,
                                    }}
                                >
                                    <DeleteIcon outlined={ isDarkMode } color='inherit' size='small' />
                                </IconButton>
                            </Box>
                        </Box>
                    </DataFieldConditional>
                ))
            ) : (
                <Grid item container spacing={ 1 } columns={ columnBase }>
                    <DataField
                        data={{
                            key: 'noPhoneDetails',
                            label: 'Phone Numbers',
                            value: '( No Phone Details Available )',
                            isEdit: false
                        }}
                        xsmobile={ 4 } mobile={ 3 } tablet={ 4 } laptop={ 5 } desktop={ 4 }
                    />
                </Grid>
            )}
           
            <DeletePhoneDialog
                show={ showDeleteConfirm }
                close={ () => { setShowDeleteConfirm( false ), setConfirmDelete( null ), setEditPhone( 'view' ) } }
                contactID={ userDetails.ContactID }
                phoneID={ confirmDelete?.PhoneID || 0 }
                phoneTypeID={ confirmDelete?.PhoneTypeID || 0 }
                phoneNumber={ confirmDelete?.PhoneNumber || '' }
                isOnlyPhone={ userDetails.PhoneNumbers.length === 1 }
                dispatch={ dispatch }
            />

        </DataGroup>
    )
}