import React, { useEffect, useState } from 'react';
import { Typography, Divider, useTheme } from '@mui/material';
import { TabsComponentContainer, TabHeaderContainer, TabHeader, TabTitle, TabContentContainer, TabContent } from '../styled';

export interface TabProps {
    tabTitle: string;
    tabComponent: React.ReactNode;
    index: number; // this is a 0 based index
} 
interface BasicTabsProps {
    tabComponents?: Array< TabProps >;
    CurrentTabIndex: ( i: number ) => void;
}
interface BasicTabsPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function BasicTabsComponent({ ...props }: BasicTabsProps ) {
    const { tabComponents, CurrentTabIndex } = props
    const [ value, setValue ] = useState< number >( 0 )
    let theme = useTheme()
    let mode = theme.palette.mode

    useEffect( () => CurrentTabIndex( value ), [ value ] )

    const handleChange = ( event: React.SyntheticEvent, newValue: number ) => {
        setValue( newValue )
    }

    return (
        <TabsComponentContainer>
            <TabHeaderContainer>
                <TabHeader 
                    value={ value } 
                    onChange={ handleChange }
                    variant='scrollable'
                    scrollButtons='auto'
                    allowScrollButtonsMobile
                >
                    { tabComponents && tabComponents.map(( tab, index ) => (
                        <TabTitle key={ index } label={ tab.tabTitle } { ...controlProps( index ) } />
                    ))}
                    { !tabComponents && (
                        <TabTitle label={ `Ahh... I am a Tab` } { ...controlProps( 0 ) } />
                    )}
                </TabHeader>
            </TabHeaderContainer>

            { tabComponents && tabComponents.map(( tab, index ) => (
                <BasicTabsPanel key={ index } value={ value } index={ index }>
                    { tab.tabComponent }
                </BasicTabsPanel>
            )) }
            { !tabComponents && (
                <BasicTabsPanel value={ value } index={ 0 }>
                    <Typography variant='h6'>We're sorry, it appears an error has occurred</Typography>
                </BasicTabsPanel>
            )}
        </TabsComponentContainer>
    )
}

function controlProps( index: number ) {
    return {
        id: `tab-${ index }`,
        'aria-controls': `tabpanel-${ index }`
    }
}

function BasicTabsPanel({ ...props }: BasicTabsPanelProps ) {
    const { children, index, value, ...other } = props

    return (
        <TabContentContainer className='tab-content-container'
            index={ index }
            value={ value }
            { ...other }
        >
            { value === index && (
                <TabContent className='tabs-content-inner-contianer'>{ children }</TabContent>
            )}
        </TabContentContainer>
    )
}