import React, { useState } from 'react';
import { Tooltip, TooltipProps, Fade, Zoom, Grow } from '@mui/material';

interface ToolTipWrapperProps {
    title: string,
    children: React.ReactElement,
    placement: "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top",
    arrow?: boolean,
    disableInteractive?: boolean,
    disableFocusListener?: boolean,
    disableHoverListener?: boolean,
    disableTouchListener?: boolean,
    enterDelay?: number, // Make enterDelay property optional
    enterNextDelay?: number,
    leaveDelay?: number,
    onClose?: () => void,
    onOpen?: () => void,
    open?: boolean,
    PopperProps?: any, // Update the type of PopperProps to allow for undefined
    TransitionType?: 'fade' | 'grow' | 'zoom',
    TransitionProps?: any,
}

export function ToolTipWrapper({ title, children, TransitionType, TransitionProps, placement, ...props }: ToolTipWrapperProps ) {
    
    return (
        <Tooltip 
            title={ title }
            TransitionProps={ TransitionProps ? TransitionProps : { timeout: 500 }}
            placement={ placement }
            TransitionComponent={ 
                TransitionType === 'fade' ? 
                ( Fade as React.JSXElementConstructor< TooltipProps[ 'TransitionProps' ] > ) : 
                TransitionType === 'grow' ? 
                ( Grow as React.JSXElementConstructor< TooltipProps[ 'TransitionProps' ] > ) : 
                ( Zoom as React.JSXElementConstructor< TooltipProps[ 'TransitionProps' ] >)
            }
            { ...props }
        >
            { children }
        </Tooltip>
    );
}