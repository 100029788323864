import React from 'react';
import { Typography } from '@mui/material';
import { StyledContentHeader } from '../../components/styled';
import { handlePackageStatusColor } from '../../components/commonUIF';

interface ContentHeaderProps {
    vehicle: string;
    status: string;
    rego: string;
}

export function AccountHeader({ vehicle, status, rego }: ContentHeaderProps ) {
    return (
        <StyledContentHeader>
            <Typography variant='h5'
                sx={{ color: handlePackageStatusColor( status ) }}
            >
               { status }
            </Typography>
            
            <Typography variant='body1'>
                { vehicle } / { rego } / Lease Account
            </Typography>

        </StyledContentHeader>
    )
}