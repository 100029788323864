import React from 'react';
import { Typography } from '@mui/material';
import { StyledContentHeader } from '../../components/styled';

interface ContentHeaderProps {
    Title: string;
    Employer: string;
    Role?: string;
}

export function ProfileHeader({ Title, Role, Employer }: ContentHeaderProps ) {
    return (
        <StyledContentHeader>
            <Typography variant='h5'>
               { Title }
            </Typography>
            
                { Role && Employer ? (
                    <Typography variant='body1'>
                        { Role } / { Employer }
                    </Typography>
                ) : (
                    <Typography variant='body1'>
                        { Employer }
                    </Typography>
                )}
                
            
        </StyledContentHeader>
    )
}