import { UserSession, IUserDetails, IPreferences } from '../constants/interfaces';
import { global_EMPTY_UUID } from '../constants';

export let initUserSession: UserSession = {
    UserID: '',
    ContactID: 0,
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    Authenticated: false,
    AuthID: global_EMPTY_UUID
}

export let initUserDetails: IUserDetails = {
    ContactID: 0,
    FirstName: '',
    LastName: '',
    PreferredName: '',
    CompanyName: '',
    CompanyStreetAddress: '',
    CompanyCity: '',
    CompanyState: '',
    CompanyPostcode: '',
    PayrollNumber: '',
    RoleTitle: '',
    Preferences: {} as IPreferences,
    PhoneNumbers: [],
    EmailAddresses: [],
    Addresses: []
}