import {
    Box,
    Typography,
    Card,
    CardContent,
    CardActions,
    Theme,
    alpha
} from '@mui/material';
import { IPackageBasicDetails } from '../../constants/interfaces';
import { handlePackageStatusColor } from '../../components/commonUIF';
import { BasicButton } from '../../components/UI';


interface PackageCardProps {
    theme: Theme;
    benefit: IPackageBasicDetails;
    isMobile: boolean;
    onCardClick: ( BenefitID: number ) => void;
}

export const PackageCard = ({ theme, benefit, onCardClick, isMobile }: PackageCardProps ) =>  (
    <Card variant='elevation'
        onClick={ () => onCardClick( benefit.BenefitID )}
        sx={{
            backdropFilter: 'blur(10px)',
            width: isMobile ? '100%' : '50%',
            marginTop: '1.6rem',
            cursor: 'pointer',
            elevation: 1,
            backgroundColor: theme.palette.mode === 'dark' ? 
                theme.palette.overlays.level1 : theme.palette.background.paper,
            '&:hover': {
                backgroundColor: alpha( theme.palette.common.skyCyan, 0.08 )
            }
        }}
    >
        <CardContent>

            <Box sx={{ marginBottom: '1rem' }}>
                <Typography
                    variant='subtitle1Bold'
                    color={ () => handlePackageStatusColor( benefit.BenefitStatus ) }
                >
                    Status: { benefit.BenefitStatus }
                </Typography>
            </Box>

            <Box sx={{ marginBottom: '1rem' }}>
                <Typography variant='body1Bold'>
                    Vehicle: { benefit.VehicleDescription }
                </Typography>
            </Box>

            <Typography variant='body1'>
                Lease Term: { benefit.LeaseTerm }
            </Typography>
            <Typography variant='body1'>
                Rego: { benefit.Rego }
            </Typography>

        </CardContent>

        <CardActions>
            <BasicButton
                text='View Details'
                variant='contained'
                color='primary'
                onClickHandler={ () => onCardClick( benefit.BenefitID ) }
            />
        </CardActions>
    </Card>
)