import React, { useEffect } from 'react';
import LogoLight from '../../../../../shared/assets/images/favicon-light.svg';
import LogoDark from '../../../../../shared/assets/images/favicon-dark.svg';
import { CustomIconButton } from '../../styled';
import {
    SearchOutlined, Search,
    GridViewOutlined, GridView,
    DirectionsCarFilledOutlined, DirectionsCarFilled,
    SavingsOutlined, Savings,
    AccountBalanceWalletOutlined, AccountBalanceWallet,
    PaidOutlined, Paid,
    FolderOpenRounded, FolderRounded, 
    QuestionAnswerOutlined, QuestionAnswer,
    UploadFileOutlined, UploadFile,
    LoginOutlined, Login,
    LogoutOutlined, Logout,
    LockOutlined, Lock,
    LockOpenOutlined, LockOpen,
    ExitToAppOutlined, ExitToApp,
    PasswordOutlined, Password,
    HelpOutlined, Help,
    MenuOutlined, Menu,
    MenuOpenOutlined, MenuOpen,
    ManageAccountsOutlined, ManageAccounts,
    AccountCircleOutlined, AccountCircle,
    NotificationsActiveOutlined, NotificationsActive,
    NotificationsNoneOutlined, NotificationsNone,
    PersonOutlined, Person,
    CheckCircleOutline, CheckCircle,
    CircleOutlined, Circle,
    LockResetOutlined, LockReset,
    EditOutlined, Edit,
    DeleteOutlined, Delete,
    SendOutlined, Send,
    AddOutlined, Add,
    SaveOutlined, Save,
    CancelOutlined, Cancel,
    LightModeOutlined, LightMode,
    DarkModeOutlined, DarkMode,
    HomeOutlined, Home,
    ReplayOutlined, Replay,
    SecurityOutlined, SecuritySharp,
    ThumbUpOutlined, ThumbUp,
    ThumbDownOutlined, ThumbDown,
    ReceiptOutlined, Receipt,
    ArrowBackOutlined, ArrowBack,
    DownloadOutlined, Download,
    FileDownloadOutlined, FileDownload,
    MailOutlineRounded, MailRounded,
    VisibilityOutlined, Visibility,
    VisibilityOffOutlined, VisibilityOff,
    Facebook, Instagram, LinkedIn, YouTube, GitHub
} from '@mui/icons-material';

// Exports from sub folders
export * from './AnimatedCheckCircle';
export * from './AnimatedCancelCircle';
export * from './InputMatchIndicator';

interface IconProps {
    size: 'small' | 'medium' | 'large' | 'inherit';
    color: "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "disabled" | "action";
    outlined: boolean;
    style?: any;
}

interface socialsProps {
    visable: boolean;
    buttonSize: 'small' | 'medium' | 'large';
    iconSize: 'small' | 'medium' | 'large' | 'inherit';
    color: string;
    link: string;
    pxIELogoSize?: string;
    mode?: 'light' | 'dark' | undefined;
    disabled?: boolean;
    doClick: ( v: string ) => void;
}

interface IESocialIconProps {
    visable: boolean;
    buttonSize: 'small' | 'medium' | 'large';
    iconSize: 'small' | 'medium' | 'large' | 'inherit';
    link: string;
    pxIELogoSize?: string;
    mode?: 'light' | 'dark' | undefined;
    disabled?: boolean;
    doClick: ( v: string ) => void;
}

interface IedgeIconProps {
    pxIELogoSize?: string | undefined;
    mode?: 'light' | 'dark' | undefined;
}

interface NotificationIconProps {
    size: 'small' | 'medium' | 'large' | 'inherit';
    notificationsNoneColor: "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "disabled" | "action";
    notificationsColor: "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "disabled" | "action";
    outlined: boolean;
    notifications: Array< Object >;
}

export function DynamicNotificationIcon({ ...props }: NotificationIconProps ) {
    const { size, notificationsColor, notificationsNoneColor, outlined, notifications } = props
    
    if ( notifications.length > 0 ) {
        return (
            <NotificationsActiveIcon outlined={ outlined } size={ size } color={ notificationsColor } />
        )
    }

    return (
        <NotificationsNoneIcon outlined={ outlined } size={ size } color={ notificationsNoneColor } />
    )
}

export const IedgeIcon = ({ pxIELogoSize, mode }: IedgeIconProps ) => <img src={ mode === 'dark' ? LogoDark : LogoLight } alt='InsideEDGE Logo' width={ pxIELogoSize ? pxIELogoSize : '24px' } height={ pxIELogoSize ? pxIELogoSize : '24px'  } />

export const HomeIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <GridViewOutlined fontSize={ size } color={ color } sx={ style } /> : <GridView fontSize={ size } color={ color } sx={ style } />

export const VehicleIcon = ({ size, color, outlined }: IconProps ) => outlined ? <DirectionsCarFilledOutlined fontSize={ size } color={ color } /> : <DirectionsCarFilled fontSize={ size } color={ color } />

export const LeaseAccountIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <SavingsOutlined fontSize={ size } color={ color } sx={ style } /> : <Savings fontSize={ size } color={ color } sx={ style } />

export const LeaseBalanceIcon = ({ size, color, outlined }: IconProps ) => outlined ? <AccountBalanceWalletOutlined fontSize={ size } color={ color } /> : <AccountBalanceWallet fontSize={ size } color={ color } />

export const ReimbursementsIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <PaidOutlined fontSize={ size } color={ color } sx={ style } /> : <Paid fontSize={ size } color={ color } sx={ style } />

export const DocumentsIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <FolderOpenRounded fontSize={ size } color={ color } sx={ style } /> : <FolderRounded fontSize={ size } color={ color } sx={ style } />

export const FAQsIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <QuestionAnswerOutlined fontSize={ size } color={ color } sx={ style } /> : <QuestionAnswer fontSize={ size } color={ color } sx={ style } />

export const ContactUsIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <MailOutlineRounded fontSize={ size } color={ color } sx={ style } /> : <MailRounded fontSize={ size } color={ color } sx={ style } />

export const LoginIcon = ({ size, color, outlined }: IconProps ) => outlined ? <LoginOutlined fontSize={ size } color={ color } /> : <Login fontSize={ size } color={ color } />

export const LogoutIcon = ({ size, color, outlined }: IconProps ) => outlined ? <LogoutOutlined fontSize={ size } color={ color } /> : <Logout fontSize={ size } color={ color } />

export const LockClosedIcon = ({ size, color, outlined }: IconProps ) => outlined ? <LockOutlined fontSize={ size } color={ color } /> : <Lock fontSize={ size } color={ color } />

export const LockOpenIcon = ({ size, color, outlined }: IconProps ) => outlined ? <LockOpenOutlined fontSize={ size } color={ color } /> : <LockOpen fontSize={ size } color={ color } />

export const ExitToAppIcon = ({ size, color, outlined }: IconProps ) => outlined ? <ExitToAppOutlined fontSize={ size } color={ color } /> : <ExitToApp fontSize={ size } color={ color } />

export const PasswordIcon = ({ size, color, outlined }: IconProps ) => outlined ? <PasswordOutlined fontSize={ size } color={ color } /> : <Password fontSize={ size } color={ color } />

export const HelpIcon = ({ size, color, outlined }: IconProps ) => outlined ? <HelpOutlined fontSize={ size } color={ color } /> : <Help fontSize={ size } color={ color } />

export const NavMenuIcon = ({ size, color, outlined }: IconProps ) => outlined ? <MenuOutlined fontSize={ size } color={ color } /> : <Menu fontSize={ size } color={ color } />

export const NavMenuOpenIcon = ({ size, color, outlined }: IconProps ) => outlined ? <MenuOpenOutlined fontSize={ size } color={ color } /> : <MenuOpen fontSize={ size } color={ color } />

export const ManagePreferencesIcon = ({ size, color, outlined }: IconProps ) => outlined ? <ManageAccountsOutlined fontSize={ size } color={ color } /> : <ManageAccounts fontSize={ size } color={ color } />

export const ProfileCircleIcon = ({ size, color, outlined }: IconProps ) => outlined ? <AccountCircleOutlined fontSize={ size } color={ color } /> : <AccountCircle fontSize={ size } color={ color } />

export const NotificationsActiveIcon = ({ size, color, outlined }: IconProps ) => outlined ? <NotificationsActiveOutlined fontSize={ size } color={ color } /> : <NotificationsActive fontSize={ size } color={ color } />

export const NotificationsNoneIcon = ({ size, color, outlined }: IconProps ) => outlined ? <NotificationsNoneOutlined fontSize={ size } color={ color } /> : <NotificationsNone fontSize={ size } color={ color } />

export const PersonIcon = ({ size, color, outlined }: IconProps ) => outlined ? <PersonOutlined fontSize={ size } color={ color } /> : <Person fontSize={ size } color={ color } />

export const CheckCircleIcon = ({ size, color, outlined }: IconProps ) => outlined ? <CheckCircleOutline fontSize={ size } color={ color } /> : <CheckCircle fontSize={ size } color={ color } />

export const LockResetIcon = ({ size, color, outlined }: IconProps ) => outlined ? <LockResetOutlined fontSize={ size } color={ color } /> : <LockReset fontSize={ size } color={ color } />

export const EditIcon = ({ size, color, outlined }: IconProps ) => outlined ? <EditOutlined fontSize={ size } color={ color } /> : <Edit fontSize={ size } color={ color } />

export const DeleteIcon = ({ size, color, outlined }: IconProps ) => outlined ? <DeleteOutlined fontSize={ size } color={ color } /> : <Delete fontSize={ size } color={ color } />

export const SendIcon = ({ size, color, outlined }: IconProps ) => outlined ? <SendOutlined fontSize={ size } color={ color } /> : <Send fontSize={ size } color={ color } />

export const AddIcon = ({ size, color, outlined }: IconProps ) => outlined ? <AddOutlined fontSize={ size } color={ color } /> : <Add fontSize={ size } color={ color } />

export const SaveIcon = ({ size, color, outlined }: IconProps ) => outlined ? <SaveOutlined fontSize={ size } color={ color } /> : <Save fontSize={ size } color={ color } />

export const CancelIcon = ({ size, color, outlined }: IconProps ) => outlined ? <CancelOutlined fontSize={ size } color={ color } /> : <Cancel fontSize={ size } color={ color } />

export const LightModeIcon = ({ size, color, outlined }: IconProps ) => outlined ? <LightModeOutlined fontSize={ size } color={ color } /> : <LightMode fontSize={ size } color={ color } />

export const DarkModeIcon = ({ size, color, outlined }: IconProps ) => outlined ? <DarkModeOutlined fontSize={ size } color={ color } /> : <DarkMode fontSize={ size } color={ color } />

export const HouseIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <HomeOutlined fontSize={ size } color={ color } sx={ style } /> : <Home fontSize={ size } color={ color } sx={ style } />

export const ReplayIcon = ({ size, color, outlined }: IconProps ) => outlined ? <ReplayOutlined fontSize={ size } color={ color } /> : <Replay fontSize={ size } color={ color } />

export const SecurityIcon = ({ size, color, outlined }: IconProps ) => outlined ? <SecurityOutlined fontSize={ size } color={ color } /> : <SecuritySharp fontSize={ size } color={ color } />

export const ThumbUpIcon = ({ size, color, outlined }: IconProps ) => outlined ? <ThumbUpOutlined fontSize={ size } color={ color } /> : <ThumbUp fontSize={ size } color={ color } />

export const ThumbDownIcon = ({ size, color, outlined }: IconProps ) => outlined ? <ThumbDownOutlined fontSize={ size } color={ color } /> : <ThumbDown fontSize={ size } color={ color } />

export const ReceiptIcon = ({ size, color, outlined }: IconProps ) => outlined ? <ReceiptOutlined fontSize={ size } color={ color } /> : <Receipt fontSize={ size } color={ color } />

export const ArrowBackIcon = ({ size, color, outlined }: IconProps ) => outlined ? <ArrowBackOutlined fontSize={ size } color={ color } /> : <ArrowBack fontSize={ size } color={ color } />

export const DownloadIcon = ({ size, color, outlined }: IconProps ) => outlined ? <DownloadOutlined fontSize={ size } color={ color } /> : <Download fontSize={ size } color={ color } />

export const DownloadDisabledIcon = ({ size, color, outlined }: IconProps ) => outlined ? <FileDownloadOutlined fontSize={ size } color={ color } /> : <FileDownload fontSize={ size } color={ color } />

export const UploadIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <UploadFileOutlined fontSize={ size } color={ color } sx={ style } /> : <UploadFile fontSize={ size } color={ color } sx={ style } />

export const DotIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <CircleOutlined fontSize={ size } color={ color } sx={ style } /> : <Circle fontSize={ size } color={ color } sx={ style } />

export const SearchIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <SearchOutlined fontSize={ size } color={ color } sx={ style } /> : <Search fontSize={ size } color={ color } sx={ style } />

export const VisibilityIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <VisibilityOutlined fontSize={ size } color={ color } sx={ style } /> : <Visibility fontSize={ size } color={ color } sx={ style } />

export const VisibilityOffIcon = ({ size, color, outlined, style }: IconProps ) => outlined ? <VisibilityOffOutlined fontSize={ size } color={ color } sx={ style } /> : <VisibilityOff fontSize={ size } color={ color } sx={ style } />

// Soccials Container Icons
export const FaceBookIC = ({ visable, buttonSize, iconSize, color, link, doClick, disabled }: socialsProps ) =>  visable ? <CustomIconButton customcolor={ color } size={ buttonSize } onClick={ () => doClick( link ) } disabled={ disabled ? true : false  }><Facebook fontSize={ iconSize } color='inherit' /></CustomIconButton> : null

export const InstagramIC = ({ visable, buttonSize, iconSize, color, link, doClick, disabled }: socialsProps ) => visable ? <CustomIconButton customcolor={ color } size={ buttonSize } onClick={ () => doClick( link ) } disabled={ disabled ? true : false  }><Instagram fontSize={ iconSize } color='inherit' /></CustomIconButton> : null

export const LinkedInIC = ({ visable, buttonSize, iconSize, color, link, doClick, disabled }: socialsProps ) => visable ? <CustomIconButton customcolor={ color } size={ buttonSize } onClick={ () => doClick( link ) } disabled={ disabled ? true : false  }><LinkedIn fontSize={ iconSize } color='inherit' /></CustomIconButton> : null

export const YouTubeIC = ({ visable, buttonSize, iconSize, color, link, doClick, disabled }: socialsProps ) => visable ? <CustomIconButton customcolor={ color } size={ buttonSize } onClick={ () => doClick( link ) } disabled={ disabled ? true : false  }><YouTube fontSize={ iconSize } color='inherit' /></CustomIconButton> : null

export const GitHubIC = ({ visable, buttonSize, iconSize, color, link, doClick, disabled }: socialsProps ) => visable ? <CustomIconButton customcolor={ color } size={ buttonSize } onClick={ () => doClick( link ) } disabled={ disabled ? true : false  }><GitHub fontSize={ iconSize } color='inherit' /></CustomIconButton> : null

export const IedgeIC = ({ visable, buttonSize, pxIELogoSize, mode, link, doClick, disabled }: IESocialIconProps ) => visable ? <CustomIconButton size={ buttonSize } onClick={ () => doClick( link ) } disabled={ disabled ? true : false  }><IedgeIcon pxIELogoSize={ pxIELogoSize } mode={ mode } /></CustomIconButton> : null