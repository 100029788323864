import React, { useState, useEffect } from 'react';
import { Typography, Collapse, Grid, useTheme } from '@mui/material';
import { IUserDetails, IUserAddress } from '../../../constants/interfaces';
import { AnimatedGridItem } from '../../../components/styled';
import { columnBase } from '../../../constants';
import { AddressType } from '../../../constants/enums';
import { DataGroup, StandardTextInput, BasicDropDownMenu, DataField } from '../../../components/UI';
import { addressTypeIDConverter, stateAcronymConverter } from '../../../components/commonUIF';
import { addNewAddress } from '../../../redux/AccessThunks';
import { useAppDispatch } from '../../../redux/Store';
import { StateSelectOptions } from '../../../constants';
import { AddAddressDialog } from './AddAddressDialog';


interface AddressComponentProps {
    userDetails: IUserDetails;
    currentEdit: 'phone' | 'email' | 'address' | 'none';
    onShowAdd: () => void;
    xsmobile: number;
    mobile: number;
    tablet: number;
    laptop: number;
    desktop: number;
}

export function AddressDetails( { userDetails, xsmobile, mobile, tablet, laptop, desktop, ...props }: AddressComponentProps ) {
    const { currentEdit, onShowAdd } = props
    const [ editAddress, setEditAddress ] = useState< 'view' | 'edit' | 'add' >( 'view' )
    const [ showAddNewAddress, setShowAddNewAddress ] = useState( false )
    const [ hasBillingAddress, setHasBillingAddress ] = useState< boolean >( 
        userDetails.Addresses.find(( addr ) => addr.AddressTypeID === AddressType.BILLING ) ? true : false 
    )
    const [ hasMailAddress, setHasMailAddress ] = useState< boolean >(
        userDetails.Addresses.find(( addr ) => addr.AddressTypeID === AddressType.MAIL ) ? true : false
    )
    const [ addressArray, setAddressArray ] = useState(
        userDetails?.Addresses 
        ? userDetails.Addresses.map(( addr ) => ({
            key: `${ addr.AddressID }`,
            label: `${ addressTypeIDConverter( addr.AddressTypeID, addr.IsCurrent ) }`,
            value: {
                UnitOrStreet: addr.UnitOrApptNo,
                Street: addr.StreetAddress,
                City: addr.City,
                State: addr.State,
                Postcode: addr.Postcode
            },
            isEdit: true,
            isCurrent: addr.IsCurrent,
            AddressTypeID: addr.AddressTypeID
        })) : []
    )
    const [ newAddress, setNewAddress ] = useState< IUserAddress >({
        ContactID: userDetails.ContactID,
        AddressID: 0,
        AddressTypeID: AddressType.HOME,
        UnitOrApptNo: null,
        StreetAddress: '',
        City: '',
        State: '',
        Postcode: 0,
        IsCurrent: false
    })
    const [ addressErrors, setAddressErrors ] = useState({
        select: false,
        Street: false,
        City: false,
        State: false,
        Postcode: false
    })
    const [ helpText, setHelpText ] = useState( '' )
    const dispatch = useAppDispatch()
    const theme = useTheme()


    useEffect(() => {
        setHasBillingAddress( userDetails.Addresses.find(( addr ) => addr.AddressTypeID === AddressType.BILLING ) ? true : false )
        setHasMailAddress( userDetails.Addresses.find(( addr ) => addr.AddressTypeID === AddressType.MAIL ) ? true : false )
        setAddressArray(
            userDetails?.Addresses 
            ? userDetails.Addresses.map(( addr ) => ({
                key: `${ addr.AddressID }`,
                label: `${ addressTypeIDConverter( addr.AddressTypeID, addr.IsCurrent ) }`,
                value: {
                    UnitOrStreet: addr.UnitOrApptNo,
                    Street: addr.StreetAddress,
                    City: addr.City,
                    State: addr.State,
                    Postcode: addr.Postcode
                },
                isEdit: true,
                isCurrent: addr.IsCurrent,
                AddressTypeID: addr.AddressTypeID
            })) : []
        )
    }, [ userDetails.Addresses ])

    useEffect(() => {
        if ( userDetails.ContactID !== 0 ) setNewAddress({ ...newAddress, ContactID: userDetails.ContactID })
    }, [ userDetails.ContactID ])

    useEffect(() => {

        if ( currentEdit !== 'address' ) onCancel()
        
    }, [ currentEdit ])

    const onAddAddress = async () => {
        setAddressErrors({ 
            select: false,
            Street: false,
            City: false,
            State: false,
            Postcode: false
        })
        setHelpText( '' )

        if ( !newAddress.AddressTypeID || AddressType[ newAddress.AddressTypeID ] === '' ) {
            setAddressErrors({ ...addressErrors, select: true })
            setHelpText( 'Please select an address type' )
            return
        }
        if ( newAddress.StreetAddress === '' || newAddress.StreetAddress === null ) {
            setAddressErrors({ ...addressErrors, Street: true })
            setHelpText( 'Please enter the street part of your address' )
            return
        }
        if (  newAddress.City === '' || newAddress.City === null ) {
            setAddressErrors({ ...addressErrors, City: true })
            setHelpText( 'Please enter the city or suburb' )
            return
        }
        if ( newAddress.State === '' || newAddress.State === null ) {
            setAddressErrors({ ...addressErrors, State: true })
            setHelpText( 'Please select the state' )
            return
        }
        if ( `${ newAddress.Postcode }`.length < 3 || `${ newAddress.Postcode }`.length > 4 ) {
            setAddressErrors({ ...addressErrors, Postcode: true })
            setHelpText( 'Please enter a valid postcode consisting of 3 - 4 numbers' )
            return
        }
        // Requires step-up auth so this will be handled by the actionInterceptor along with the outcomes
        await dispatch( addNewAddress( newAddress )).unwrap()
        onCancel()
    }

    const onCancel = () => {
        setHelpText( '' )
        setShowAddNewAddress( false )
        setEditAddress( 'view' )
        setAddressErrors({ 
            select: false,
            Street: false,
            City: false,
            State: false,
            Postcode: false
        })
        setNewAddress({
            ContactID: userDetails.ContactID,
            AddressID: 0,
            AddressTypeID: AddressType.HOME,
            UnitOrApptNo: null,
            StreetAddress: '',
            City: '',
            State: '',
            Postcode: 0,
            IsCurrent: false
        })
    }

    const handleAddClick = () => {
        onShowAdd()
        setShowAddNewAddress( true )
        setEditAddress( 'edit' )
    }

    return (
        <DataGroup 
            title='Address Details'
            subGroup
            hideBackground
            editing={ editAddress !== 'view' }
            onAddClick={ handleAddClick }
            onSaveClick={ onAddAddress }
            onCancelClick={ onCancel }
            xsmobile={ xsmobile } mobile={ mobile } tablet={ tablet } laptop={ laptop } desktop={ desktop }
        >
            {/* Below Grid Item opens downward when "showAddPhone" == true */}
            <Collapse in={ showAddNewAddress && currentEdit === 'address' } unmountOnExit
                sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            >
                <AnimatedGridItem container
                    show={ showAddNewAddress === true ? 'true' : 'false' }
                    columns={ columnBase }
                    spacing={ 1 }
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        width: '100%',
                        paddingLeft: '0.9rem',
                        paddingBottom: '0.5rem',
                    }}
                >
                    {/* Address Type */}
                    <Grid item
                        xsmobile={ 4 } mobile={ 5 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                    >
                        <BasicDropDownMenu
                            menuLabel='Address Type'
                            selectID='AddressType'
                            size='small'
                            options={[
                                { key: 1, label: 'Home', value: AddressType.HOME },
                                { key: 2, label: 'Mailing Address', value: AddressType.MAIL },
                                { key: 3, label: 'Billing Address', value: AddressType.BILLING }
                            ]}
                            onChange={ ( v ) => setNewAddress({ ...newAddress, AddressTypeID: v  }) }
                            error={ addressErrors.select === true }
                        />
                    </Grid>
                    
                    {/* Unit/Apt No. & Street Address */}
                    <Grid item
                        xsmobile={ 4 } mobile={ 5 } tablet={ 6 } laptop={ 8 } desktop={ 4 }
                    >
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='Street Address'
                            placeholder='Enter Street Address'
                            value={ newAddress.StreetAddress }
                            type='text'
                            disabled={ !showAddNewAddress }
                            onChange={ ( value ) => {
                                let cleanVal = value.replace( /[^A-Za-z0-9/\s-]/g, '' );
                                cleanVal = cleanVal.slice( 0, 60 );
                                setNewAddress({ ...newAddress, StreetAddress: cleanVal })
                            }}
                            error={ addressErrors.Street === true }
                        />
                    </Grid>

                    {/* Suburb/City */}
                    <Grid item
                        xsmobile={ 4 } mobile={ 5 } tablet={ 4 } laptop={ 6 } desktop={ 3 }
                    >
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='City/Suburb'
                            placeholder='Enter City/Suburb'
                            value={ newAddress.City }
                            type='text'
                            disabled={ !showAddNewAddress }
                            onChange={ ( value ) => {
                                let cleanVal = value.replace( /[^A-Za-z\s-]/g, '' );
                                cleanVal = cleanVal.slice( 0, 50 );
                                setNewAddress({ ...newAddress, City: cleanVal })
                            }}
                            error={ addressErrors.City === true }
                        />
                    </Grid>

                    {/* State */}
                    <Grid item
                        xsmobile={ 4 } mobile={ 5 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                    >
                        <BasicDropDownMenu
                            menuLabel='State'
                            selectID='StateSelect'
                            size='small'
                            options={ StateSelectOptions }  
                            onChange={ ( v ) => setNewAddress({ ...newAddress, State: v  }) }
                            error={ addressErrors.State === true }
                        />
                    </Grid>
                        
                    {/* Postcode */}
                    <Grid item
                        xsmobile={ 4 } mobile={ 5 } tablet={ 2 } laptop={ 2 } desktop={ 1 }
                    >
                        <StandardTextInput
                            variant='standard'
                            size='small'
                            label='Postcode'
                            placeholder='Enter Postcode'
                            value={ newAddress.Postcode }
                            type='text'
                            disabled={ !showAddNewAddress }
                            onChange={ ( value ) => {
                                let cleanVal = value.replace( /[^0-9]/g, '' );
                                cleanVal = cleanVal.slice( 0, 4 );
                                setNewAddress({ ...newAddress, Postcode: cleanVal })
                            }}
                            error={ addressErrors.Postcode === true }
                        />
                    </Grid>

                    <Typography variant='caption' sx={{ color: theme.palette.error.main, height: '1rem' }}>
                        { addressErrors && helpText }
                    </Typography>

                </AnimatedGridItem>
            </Collapse>
           
            { addressArray.length > 0 ? (
                addressArray.map(( addr ) => (
                    <Grid item 
                        key={ `${ addr.key }` }
                        container 
                        spacing={ 1 } 
                        columns={ columnBase }
                    >

                        <DataField
                            data={{
                                key: `${ addr.key }addressType`,
                                label: 'Address Type',
                                value: addr.label,
                                isEdit: false
                            }}
                            xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                        />
                        
                        {/* Street: string; */}
                        <DataField
                            data={{
                                key: `${ addr.key }address`,
                                label: 'Address',
                                value: `${ addr.value.UnitOrStreet ? addr.value.UnitOrStreet : '' }${ addr.value.UnitOrStreet ? ' ' : '' }${ addr.value.Street }, ${ addr.value.City }, ${ stateAcronymConverter( addr.value.State ) }, ${ addr.value.Postcode }`,
                                isEdit: false
                            }}
                            xsmobile={ 4 } mobile={ 3 } tablet={ 6 } laptop={ 8 } desktop={ 10 }
                        />

                    </Grid>
                ))
            ) : (
                <Grid item container spacing={ 1 } columns={ columnBase }>
                    <DataField
                        data={{
                            key: 'noAddressDetails',
                            label: 'Address Type',
                            value: 'Address Details',
                            isEdit: false
                        }}
                        xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                    />
                    
                    <DataField
                        data={{
                            key: 'noAddressDetails',
                            label: 'Address',
                            value: '( No Address Details )',
                            isEdit: false
                        }}
                        xsmobile={ 4 } mobile={ 3 } tablet={ 6 } laptop={ 8 } desktop={ 10 }
                    />
                </Grid>
            )}

            { !hasMailAddress && (
                <Grid item container spacing={ 1 } columns={ columnBase }>
                    <DataField
                        data={{
                            key: 'noMailingAddress',
                            label: 'Address Type',
                            value: 'Mailing Address',
                            isEdit: false
                        }}
                        xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                    />
                    
                    <DataField
                        data={{
                            key: 'noMailingAddress',
                            label: 'Address',
                            value: '( No Mailing Address Specified )',
                            isEdit: false
                        }}
                        xsmobile={ 4 } mobile={ 3 } tablet={ 6 } laptop={ 8 } desktop={ 10 }
                    />
                </Grid>
            )}

            { !hasBillingAddress && (
                <Grid item container spacing={ 1 } columns={ columnBase }>
                    <DataField
                        data={{
                            key: 'noBillingAddress',
                            label: 'Address Type',
                            value: 'Billing Address',
                            isEdit: false
                        }}
                        xsmobile={ 2 } mobile={ 2 } tablet={ 2 } laptop={ 2 } desktop={ 2 }
                    />
                    
                    <DataField
                        data={{
                            key: 'noBillingAddress',
                            label: 'Address',
                            value: '( No Billing Address Specified )',
                            isEdit: false
                        }}
                        xsmobile={ 4 } mobile={ 3 } tablet={ 6 } laptop={ 8 } desktop={ 10 }
                    />
                </Grid>
            )}

        </DataGroup>
    )
}