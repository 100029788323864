// Component inports
import {
    Grid,
    Card,
    CardContent,
    CardActionArea,
    CardActions,
    Typography,
    Button
} from '@mui/material';
import { 
    NavBarMobile,
    MobileNavigationContainer,
    BaseFooter,
    NavMenu,
    MainGrid,
    PreAuthMainGrid,
    PreAuthBaseFooter
} from '../../components/layout';
import {
    PreAuthOuterContainer,
    RootContainer,
    OuterContainer,
    InnerContainer,
    NavBar
} from '../../components/styled';
import { DrawerContextProvider } from '../../context/DrawerContext';
import { Helmet } from 'react-helmet-async';

// Directory exports
export * from './ErrorBoundaryFallback';



export const PreAuthErrorBoundary = ({ error, appName, version, resetBoundary }: {
    error: any,
    appName: string,
    version: string,
    resetBoundary: () => void
}) => (
    <RootContainer className='rootContainer'>  
        <Helmet>
            <meta charSet='utf-8' />
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <title>InisdeEDGE - AutoDash</title>
            <link rel='preconnect' href='https://fonts.googleapis.com' />
            <link rel='stylesheet' href='https://fonts.googleapis.com/icon?family=Material+Icons' />
        </Helmet>

        <PreAuthOuterContainer className='outerContainer'>
                
            <PreAuthMainGrid>
                <MainErrorComponent
                    error={ error }
                    resetBoundary={ resetBoundary }
                />
            </PreAuthMainGrid>
            
            <PreAuthBaseFooter
                appName={ appName }
                version={ version }
            />

        </PreAuthOuterContainer>
    </RootContainer>
)


export const AuthErrorBoundary = ({ error, nav, appName, version, width, resetBoundary }: {
    error: any,
    nav: boolean,
    appName: string,
    version: string,
    width: boolean,
    resetBoundary: () => void
}) => (
    <RootContainer className='RootContainer'>
        <Helmet>
            <meta charSet='utf-8' />
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <title>InisdeEDGE - AutoDash</title>
            <link rel='preconnect' href='https://fonts.googleapis.com' />
            <link rel='stylesheet' href='https://fonts.googleapis.com/icon?family=Material+Icons' />
        </Helmet>
        
        <DrawerContextProvider>
            <OuterContainer className='OuterContainer'> 
                
                <NavMenu />

                <InnerContainer className='ContentContainer' navmenu={ nav }>
                    
                    <NavBar />
                    
                    <MainGrid>
                        <MainErrorComponent
                            error={ error }
                            resetBoundary={ resetBoundary }
                        />
                    </MainGrid>

                    <BaseFooter
                        appName={ appName }
                        version={ version }
                    />
                    
                </InnerContainer>

                <MobileNavigationContainer isMobileView={ width }>
                    
                    <NavBarMobile />

                </MobileNavigationContainer>

            </OuterContainer>
        </DrawerContextProvider>
    </RootContainer>
)

export const MainErrorComponent = ({ error, resetBoundary }: { error: any, resetBoundary: () => void }) => (
    <Grid container role="alert">
        <Grid item>
            <Card sx={{ padding: 1 }}>
                <CardContent>
                    <Typography variant="subtitle2">Whoops :/ sorry, things happened & things got broken.</Typography>
                    <Typography variant="body2">The details are here: </Typography>
                        { error && <Typography>{ `${ error }` }</Typography> }
                    <CardActionArea>
                        <CardActions>
                            <Button variant='contained' color='primary' fullWidth size='small' onClick={ resetBoundary }>
                                <Typography variant='button'>Try again</Typography>
                            </Button>
                        </CardActions>
                    </CardActionArea>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
)