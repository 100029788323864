import { DateTime } from 'luxon';
import Joi from 'joi';

export const PASSWORD_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const NAME_REGEX = /^(?!^\s*\.?$)[A-Za-z'-]+$/;

export const NUMBERS_ONLY_REGEX = /^[0-9]+$/;

export const DECIMAL_REGEX = /^\d*(\.\d{0,2})?$/

export const reimbursmentSubmissionSchema = Joi.object({
    ContactID: Joi.number().integer().min( 1 ).required(),
    SubmittedByID: Joi.number().integer().min( 1 ).allow( '', null ).optional().messages({
        'number.base': 'Invalid Input: SubmittedByID',
        'number.min': 'Invalid Input: SubmittedByID',
        'number.empty': 'Invalid Input: SubmittedByID'
    }),
    BenefitID: Joi.number().integer().min( 1 ).required().messages({
        'number.base': 'Invalid package option selected',
        'number.min': 'Please select a valid package option',
        'number.empty': 'Please select a packge option',
        'any.required': 'Please select a package option'
    }),
    BenefitDesc: Joi.string().required(),
    Odometer: Joi.number().integer().min( 1 ).required().messages({
        'number.base': 'Odo reading should be a number'
    }),
    BenefitOperCostTypeID: Joi.number().integer().min( 1 ).required().messages({
        'number.base': 'Invalid Input: BenefitOperCostTypeID',
        'number.min': 'Invalid Input: BenefitOperCostTypeID',
        'number.max': 'Invalid Input: BenefitOperCostTypeID',
        'number.empty': 'Please select an operating cost type',
        'any.required': 'Please select an operating cost type'
    }),
    BenefitOperCostName: Joi.string().required().messages({
        'string.base': 'Invalid operating cost name',
        'string.min': 'Invalid operating cost name',
        'string.max': 'Invaid operating cost name',
        'string.empty': 'Please enter an operating cost name',
        'any.required': 'Please enter an operating cost name'
    }),
    PurchaseDate: Joi.string().required().custom( ( value, helpers ) => {
        console.log( value );
        let date = DateTime.fromISO( value, { zone: 'utc' } );
        if ( !date.isValid ) {
            return helpers.error( 'any.invalid', { message: 'Invalid Date' });
        }

        const today = DateTime.utc().startOf( 'day' );
        const purchaseDate = date.startOf( 'day' );

        if ( purchaseDate > today ) {
            return helpers.error( 'any.invalid', { message: 'Purchase date cannot be in the future' });
        }

        return value;
    }),
    SupplierCompanyName: Joi.string().min( 2 ).max( 50 ).required().messages({
        'string.base': 'Invalid supplier name',
        'string.min': 'Supplier too short, if you entered an acronym please enter the full name',
        'string.max': 'Supplier too long, please keep it within 50 characters',
        'string.empty': 'Please enter a supplier name',
        'any.required': 'Please enter a supplier name'
    }),
    IsNoReferenceNumber: Joi.boolean().required(),
    SupplierRefText: Joi.string().min( 1 ).max( 250 ).allow( "", null ).optional().messages({
        'string.base': 'Invalid reference number',
        'string.max': 'Reference number too long, please keep it within 30 characters'
    }),
    AmountIncTax: Joi.string().regex( DECIMAL_REGEX ).required().messages({
        'string.base': 'Please enter a valid amount',
        'string.pattern.base': 'Please make sure the max decimal places is 2',
        'string.empty': 'Please enter an amount',
        'any.required': 'Please enter an amount'
    }),
    AmountTax: Joi.string().regex( DECIMAL_REGEX ).required().messages({
        'string.base': 'Please enter a valid amount',
        'string.pattern.base': 'Please make sure the max decimal places is 2',
        'string.empty': 'Please enter an amount',
        'any.required': 'Please enter an amount'
    }),
    UserComment: Joi.string().max( 250 ).allow( "" ).optional().messages({
        'string.base': 'Invalid comment',
        'string.max': 'Comment too long, please keep it within 250 characters',
    }),
    BankAccountID: Joi.number().integer().min( 1 ).required().messages({
        'number.base': 'Invalid Input: BankAccountID',
        'number.empty': 'Please select an account to be reimbursed',
        'any.required': 'Please select an account to be reimbursed'
    }),
    IsGSTOverride: Joi.boolean().required(),
    FileAttachments: Joi.array()
        .items(
            {
                FileName: Joi.string().required(),
                FileSize: Joi.alternatives().try( Joi.string(), Joi.number() ).allow( "", null ).optional(), 
                FileType: Joi.string().allow( "", null ).optional(),
                FileData: Joi.string().required()
            }
        )
        .min( 1 )
        .required()
        .messages(
        {
            'array.base': 'Invalid uploads',
            'array.min': 'Please attach at least one file as supporting evidence',
            'any.required': 'Please attach at least one file as supporting evidence'
        }
    )
});


export function mapErrorMessages( 
    errors: Array<{ field: string, message: string }>,
    errorMap: Record< string, string > 
): Record< string, string > {
    if ( errors.length === 0 ) {
        for ( let key in errorMap ) {
            errorMap[ key ] = '';
        }
        return errorMap;
    }
    errors.forEach(( error ) => {
        if ( errorMap.hasOwnProperty( error.field )) {
            errorMap[ error.field ] = error.message;
        }
        // else discard it
    })
    return errorMap;
}