import { styled, TableCell } from "@mui/material";
import { PieChart, BarPlot } from "@mui/x-charts";

export const PieChartCustom = styled( PieChart )(({ theme }) => ({
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

}))

export const BarGaugeChart = styled( BarPlot )(({ theme }) => ({


}))

export const Cell = styled( TableCell )(({ theme }) => ({
    paddingTop: '0.8rem',
    paddingBottom: '0.8rem'
}))