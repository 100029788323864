import React, { useEffect } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { RoutePaths } from "../constants/enums";
import { useAuth } from "../hooks/useAuth";

interface IAuthProps {
    children: React.ReactNode
}

export function RequireAuth() {
    const { Authenticated, loading } = useAuth()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect( () => {
        if ( !loading && !Authenticated ) {
            sessionStorage.setItem( 'lastPath', location.pathname )
            navigate( RoutePaths.ROOT, { state: { from: location.pathname }, replace: true })
        } else if ( !loading && Authenticated ) {
            const lastPath = sessionStorage.getItem( 'lastPath' )
            if ( lastPath && lastPath !== RoutePaths.ROOT ) {
                navigate( lastPath )
                sessionStorage.removeItem( 'lastPath' )
            }
        }
    }, [ Authenticated, loading, navigate, location ])

    return Authenticated ? <Outlet /> : null
}