import React from 'react';
import { useAppSelector } from '../../redux/Store';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavMenuItems } from '../../constants';
import { NavMenuLogo } from '../UI';
import { 
    useTheme,
    ListItemText
} from '@mui/material';
import {
    NavMenuDrawer,
    StyledList,
    StyledListItem,
    StyledListItemButton,
    StyledListItemIcon
} from '../styled'


export function NavMenu() {
    const { navMenu } = useAppSelector(( state ) => state.AppState )
    const theme = useTheme()
    const navigate = useNavigate()
    const location = useLocation()

    const menuNavigator = ( path: string ) => {
        navigate( path, { state: { from: location.pathname }})
    }

    return (
        <NavMenuDrawer
            theme={ theme }
            variant='permanent'
            open={ navMenu }
            isOpened={ navMenu }
        >
            <NavMenuLogo size='5rem' theme={ theme } />
            <StyledList>
                { NavMenuItems.map(( item ) => (
                    <StyledListItem key={ item.label }>
                        <StyledListItemButton onClick={ () => menuNavigator( item.path )}>
                            <StyledListItemIcon >
                                { item.icon }
                            </StyledListItemIcon>
                            <ListItemText primary={ item.label } />
                        </StyledListItemButton>
                    </StyledListItem>
                ))}
            </StyledList>
        </NavMenuDrawer>
    )
}

















