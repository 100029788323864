import { Typography, Grid, useTheme } from '@mui/material';
import { PreAuthFooter } from '../styled';
import { Socials } from '../UI/Socials';


interface Props {
    appName?: string;
    version?: string;
}

export function PreAuthBaseFooter({ version, appName }: Props ) {
    const theme = useTheme();
    return (
        <PreAuthFooter className='pre-auth-footer'>
            <Grid container columns={ 2 } display='flex' flexDirection='row' width='100%'>

                <Grid item justifyContent='flex-start' alignItems='center' mt={ 0.7 }
                    xsmobile={ 1 } mobile={ 1 } tablet={ 1 } laptop={ 1 } desktop={ 1 }
                >
                    <Typography variant='caption' sx={{ color: theme.palette.common.softLight }}>
                        © Copyright - { appName }{ ' ' }V{ version }
                    </Typography>
                </Grid>

                <Grid item justifyContent='flex-end' alignItems='center'
                    xsmobile={ 1 } mobile={ 1 } tablet={ 1 } laptop={ 1 } desktop={ 1 }
                >
                    <Socials className='socials'
                        align='flex-end'
                        buttonSizes='small'
                        iconSizes='small'
                        IElogoPx='14px'
                        color={ theme.palette.primary.main }
                        login
                        WB 
                        FB 
                        IG 
                        LI 
                        YT 
                        GH
                    />
                </Grid>
                
            </Grid>
        </PreAuthFooter>
    )
}