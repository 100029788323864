import React from 'react';
import { useAppSelector } from '../../redux/Store';
import { Drawer } from '@mui/material';

interface MobileNavigationContainerProps {
    children: React.ReactNode;
    isMobileView: boolean;
}

export function MobileNavigationContainer({ ...props }: MobileNavigationContainerProps ) {
    const { navMenu } = useAppSelector(( state ) => state.AppState )
    const { children, isMobileView } = props;
    let open = navMenu === false && isMobileView ? true : false;

    return (
        <Drawer
            anchor='bottom'
            variant='persistent'
            open={ open }
            sx={{
                [ `& .MuiDrawer-paper` ]: {
                    backgroundColor: 'transparent'
                }
            }}
        >
            { children }
        </Drawer>
    );
}