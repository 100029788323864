import React, { useMemo, useState } from 'react';
import { LinearProgress, lighten, alpha, useTheme } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../redux/Store';
import { DataGridPremium, GridColDef, GridToolbar, useGridApiRef, GridAggregationModel, GridRowSelectionModel  } from '@mui/x-data-grid-premium';

type MUX_DataGridProps = {
    columns: Array< GridColDef >; // Import the "GridColDef" from '@mui/x-data-grid-premium' on the component passing the prop
    rows: Array<any>;
    rowId: ( row: any ) => any;
    hideFooter?: boolean;
    pagination?: boolean;
    disableColumnSelector?: boolean;
    disableColumnMenus?: boolean;
    // includeAggregation: boolean;
    // aggregationModel?: GridAggregationModel;
    includeGroupingColDef: boolean;
    groupingColDef?: any;
    includeInitialState: boolean;
    initialState?: object;
    loading?: boolean;
    // selectedRows?: ( v: Array< any > ) => any;
    pageSizeOptions?: Array<number>;
    // fullGridToolBar?: boolean;
    // customToolBar?: any;
    // slotProps?: {},
    autoPageSize?: boolean;
    rowHeight?: number;
}

// const useStyles = makeStyles({
//     pointerCursor: {
//         '& .MuiDataGrid-row': {
//             cursor: 'pointer',
//         },
//     } 
// });

export function MUX_DataGrid( props: MUX_DataGridProps ) {
    const {
        columns,
        rows,
        rowId,
        disableColumnSelector,
        disableColumnMenus,
        // includeAggregation,
        // aggregationModel,
        includeGroupingColDef,
        groupingColDef,
        includeInitialState,
        initialState,
        hideFooter,
        loading,
        // selectedRows,
        pageSizeOptions,
        // fullGridToolBar,
        // customToolBar,
        // slotProps,
        pagination,
        autoPageSize,
        rowHeight,
        // onColumnOrderChange,
        // columnVisibilityModel,
        // onColumnVisibilityChange
    } = props;
    const { processing } = useAppSelector(( state ) => state.AppState )
    // const classes = useStyles();
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    

    return (

        <DataGridPremium
            // className={ classes.pointerCursor }
            // loading={ loading || processing }
            
            columns={ columns }
            disableColumnSelector={ disableColumnSelector ? true : false }
            disableColumnMenu={ disableColumnMenus ? true : false }
            rows={ rows }
            getRowId={ rowId }
            rowHeight={ rowHeight ? rowHeight : 30 }
            hideFooter={ hideFooter ? true : false }
            // aggregationModel={ includeAggregation ? aggregationModel : {} }
            groupingColDef={ includeGroupingColDef ? groupingColDef : undefined }
            initialState={ includeInitialState ? { ...initialState } : {} }
            pageSizeOptions={ pageSizeOptions ? pageSizeOptions : [] }
            // onRowSelectionModelChange={
            //     selectedRows ?
            //     ( rowSelectionModel: GridRowSelectionModel ) => selectedRows( rowSelectionModel )
            //     : null 
            // }
            // slots={{ 
            //     toolbar: fullGridToolBar ? GridToolbar : customToolBar ? customToolBar : null, 
            //     loadingOverlay: LinearProgress  
            // }}
            // slotProps={
            //     slotProps ? { ...slotProps } : {}
            // }
            pagination={ pagination ? true : false }
            autoPageSize={ autoPageSize ? true : false }
            // onColumnOrderChange={ onColumnOrderChange ? onColumnOrderChange : null }
            // columnVisibilityModel={ columnVisibilityModel ? columnVisibilityModel : {} }
            // onColumnVisibilityModelChange={ onColumnVisibilityChange ? onColumnVisibilityChange : null }
            sx={{
                backgroundColor: isDark ? theme.palette.overlays.level1 : theme.palette.background.paper,
                '& .MuiDataGrid-columnHeader': {
                    backgroundColor: isDark ? '' : theme.palette.background.paper,
                    backgroundImage: isDark ? `
                        linear-gradient( ${ theme.palette.overlays.level1 }, ${ theme.palette.overlays.level1 } ),
                        linear-gradient( ${ theme.palette.overlays.level2 }, ${ theme.palette.overlays.level2 } ),
                        linear-gradient( ${ theme.palette.background.backgroundOverlay }, ${ theme.palette.background.backgroundOverlay } )
                    `
                    : 'none',
                },
                '& .MuiDataGrid-filler': {
                    backgroundColor: isDark ? '' : theme.palette.background.paper,
                    backgroundImage: isDark ? `
                        linear-gradient( ${ theme.palette.overlays.level1 }, ${ theme.palette.overlays.level1 } ),
                        linear-gradient( ${ theme.palette.overlays.level2 }, ${ theme.palette.overlays.level2 } ),
                        linear-gradient( ${ theme.palette.background.backgroundOverlay }, ${ theme.palette.background.backgroundOverlay } )
                    `
                    : 'none',
                }
            }}
            
            // sx={{
            //     '& .MuiDataGrid-pinnedRowsRenderZone': {
            //         backgroundColor: `${ mode ? '' : 'rgba( 23, 124, 186, 0.04 )' }`
            //     },
            //     '& .MuiDataGrid-withBorderColor': {
            //         borderBottomColor: `${ mode ? '' : '' }`
            //     },
            //     '& .MuiIconButton-root': {
            //         color: `${ mode ? theme.palette.info.main : theme.palette.info.dark }`
            //     },
            //     '& .MuiButtonBase-root': {
            //         '& .MuiButton-root': { 
            //             '& .MuiButton-outlined': {
            //                 '& .MuiButton-outlinedSuccess': {
            //                     color: `${ mode ? '' : 'rgb(102, 187, 106)' }`
            //                 }
            //             }
            //         },
            //     },
            //     '& .MuiDataGrid-overlay': {
            //        backgroundColor : `${ mode ? '' :  '' }`,
            //     },
            //     '& .MuiDataGrid-columnHeaders': {
            //         minHeight: '2rem !important',
            //         maxHeight: '2rem !important',
            //         lineHeight: '2rem !important',
            //     },
            //     '& .MuiDataGrid-footerContainer': {
            //         minHeight: '2rem !important',
            //         maxHeight: '2rem !important',
            //         lineHeight: '2rem !important'
            //     },
            //     '& .MuiDataGrid-row': {
            //         '&:hover': {
            //             backgroundColor: `${ mode ? alpha( theme.palette.info.dark, 0.2 ) : 'rgba( 23, 124, 186, 0.08 )' }`,
            //             border: `1px solid ${ mode ? theme.palette.info.dark : theme.palette.info.light }`,
            //         }
            //     },
            //     backgroundColor: `${ mode ? '' : 'rgba( 23, 124, 186, 0.04 )' }`,
            //     color: theme.palette.dataTable.cellTextColor,
            //     borderColor: `${ mode ? theme.palette.info.dark : theme.palette.info.light }`,
            // }}
        />

    )
}