import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { IUserPhone } from '../../../constants/interfaces';
import { PhoneType } from '../../../constants/enums';
import { deletePhone } from '../../../redux/AccessThunks';
import { AppDispatch } from '../../../redux/Store';
import { formatPhoneByType } from '../../../components/commonUIF';
import { Dialog_DetailEdit } from '../../../components/UI';



interface DeletePhoneDialogProps {
    show: boolean;
    close: () => void;
    contactID: number;
    phoneID: number;
    phoneTypeID: number;
    phoneNumber: string;
    isOnlyPhone: boolean;
    dispatch: AppDispatch;
}

export const DeletePhoneDialog = ({ ...props }: DeletePhoneDialogProps ) =>  {
    const { show, close, contactID, phoneID, phoneTypeID, phoneNumber, isOnlyPhone, dispatch } = props

    const onCancel = () => {
        close()
    }

    const onDelete = async () => {
        if ( isOnlyPhone ) {
            return alert( "This is the only contact number we have for you. You can't do this. Nope." )
        }
        await dispatch( deletePhone({ ContactID: contactID, PhoneID: phoneID }))
        close()
    }

    return (
        <Dialog_DetailEdit
            show={ show }
            title='Please Confirm'
            confirmLabel='Yes'
            cancelLabel='Whoops, No!'
            slideDirection='up'
            onConfirm={ onDelete }
            onCancel={ onCancel }
        >
            <Grid container spacing={ 2 }>
                <Grid item>
                    <Typography variant='body1'>
                        Are you sure you want to delete this phone number?
                        <br />
                        <Typography variant='body1Bold'>{ formatPhoneByType( phoneNumber, phoneTypeID ) }</Typography>
                    </Typography>
                </Grid>
            </Grid>
        </Dialog_DetailEdit>
    )
}