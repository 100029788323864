import React, { useState } from 'react';
import { styled, Button, Typography, useTheme } from '@mui/material';


interface BasicButtonProps {
    text?: string;
    icon?: React.ReactNode;
    iconPosition?: 'start' | 'end';
    disabled?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info';
    size?: 'small' | 'medium' | 'large';
    freezeVariant?: boolean;
    onClickHandler?: () => void;
}

export function BasicButton({ text, variant, disabled, color, size, icon, iconPosition, onClickHandler, freezeVariant }: BasicButtonProps ) {
    const isDarkMode = useTheme().palette.mode === 'dark';
    return (
        <StandardButton
            variant={ isDarkMode && !freezeVariant ? 'outlined' : variant ? variant : 'contained' }
            disabled={ disabled ? disabled : false }
            color={ color ? color : 'primary' }
            size={ size ? size : 'medium' }
            startIcon={ iconPosition === 'start' ? icon : null }
            endIcon={ iconPosition === 'end' ? icon : null }
            onClick={ onClickHandler }
            sx={{ color: disabled ? 'text.disabled' : '' }}
        >
            <Typography>{ text }</Typography>
        </StandardButton>
    )
}


const StandardButton = styled( Button )(({ theme }) => ({
    textTransform: 'none'
}))