import { styled, Alert, Theme, alpha } from '@mui/material';


const handleAlertColor = ( theme: Theme, type: string ): { bg: string, bgd: string, txt: string } => {
    switch ( type ) {
        case 'info':
            return { bg: theme.palette.info.main, bgd: alpha( theme.palette.info.main, 0.5 ), txt: theme.palette.text.primary };
        case 'success':
            return { bg: theme.palette.success.main, bgd: alpha( theme.palette.success.main, 0.5 ), txt: theme.palette.text.primary };
        case 'warning':
            return { bg: theme.palette.warning.main, bgd: alpha( theme.palette.success.main, 0.5 ), txt: theme.palette.text.primary };
        case 'error':
            return { bg: theme.palette.error.main, bgd: alpha( theme.palette.success.main, 0.5 ), txt: theme.palette.text.primary };
        default:
            return { bg: theme.palette.background.default, bgd: alpha( theme.palette.success.main, 0.5 ), txt: theme.palette.text.primary };
    }
}

export const AppAlert = styled( Alert, { shouldForwardProp: ( prop ) => prop !== 'type' })<{ 
    type: 'info' | 'success' | 'warning' | 'error',
    ismobile: string
}>(({ theme, type, ismobile }) => ({
    display: 'flex',
    padding: '0.5rem',
    alignItems: 'center',
    height: '2.5rem',
    width: ismobile === 'true' ? '250px' : '25%',
    maxWidth: '250px',
    color: handleAlertColor( theme, type ).txt,
    background: theme.palette.mode === 'dark' ? handleAlertColor( theme, type ).bgd : handleAlertColor( theme, type ).bg,
}))

